import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  CardContent,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const NameChangeDetails = (props) => {
  // const defaultValue = props.formData?.NameChangeDetails?.[0]
  //   ?.nameChangeIssueDate
  //   ? props.formData.NameChangeDetails[0].nameChangeIssueDate
  //   : null;
  const [details, setDetails] = useState(
    props.formdata?.NameChangeDetails || [
      {
        nameChangeOldName: "",
        nameChangeNewName: "",
        nameChangeAddress: "",
        nameChangeCertificateNumber: "",
        nameChangeIssueDate: "",
        ImageURI: "",
      },
    ]
  );

  const handleInputChange = (field, value) => {
    setDetails((prevState) => {
      const newState = [...prevState];
      newState[0][field] = value;
      return newState;
    });
  };

  const hasValidValues = () => {
    for (const nameChangeEntry of details) {
      if (
        nameChangeEntry.nameChangeNewName === "" ||
        nameChangeEntry.nameChangeOldName === "" ||
        nameChangeEntry.nameChangeCertificateNumber === "" ||
        nameChangeEntry.nameChangeIssueDate === "" ||
        nameChangeEntry.nameChangeAddress === ""
      ) {
        return false;
      }
      // if (!dirEntry.ImageURI) {
      //   return false;
      // }
    }
    return true;
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    props.nextStep();
  };

  const handlePrevClick = (e) => {
    e.preventDefault();
    props.prevStep();
  };

  useEffect(() => {
    const page = "NameChangeDetails";
    const value = [...details];
    console.log("handleSubmit event NameChangeDetails: ", page, value);
    props.onChangeformvalue(page, value);
  }, [details]);

  return (
    <Fragment>
      <CardContent>
        {/* Grid 1: Header Row */}
        <Grid container>
          <Grid item xs={12} md={7}>
            <Typography
              variant="h6"
              style={{ fontSize: "x-large", fontWeight: "bold" }}
            >
              Official Name Change Details
            </Typography>
          </Grid>
          {/* <Grid item xs={12} md={5}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                // onClick={handleAddEntry}
              >
                Upload Document
              </Button>
            </Box>
          </Grid> */}
        </Grid>

        {/* Grid 2: Form Field */}
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <TextField
              label="New Name"
              variant="outlined"
              fullWidth
              value={props.formdata.NameChangeDetails?.[0]?.nameChangeNewName}
              onChange={(e) =>
                handleInputChange("nameChangeNewName", e.target.value)
              }
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Old Name"
              variant="outlined"
              fullWidth
              value={props.formdata.NameChangeDetails?.[0]?.nameChangeOldName}
              onChange={(e) =>
                handleInputChange("nameChangeOldName", e.target.value)
              }
              required
            />
          </Grid>

          <Grid item xs={24} md={12}>
            <TextField
              label="Address (as in certificate / affidavit)"
              variant="outlined"
              fullWidth
              value={props.formdata.NameChangeDetails?.[0]?.nameChangeAddress}
              onChange={(e) =>
                handleInputChange("nameChangeAddress", e.target.value)
              }
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Name Change Certificate Number"
              variant="outlined"
              fullWidth
              value={
                props.formdata.NameChangeDetails?.[0]
                  ?.nameChangeCertificateNumber
              }
              onChange={(e) =>
                handleInputChange("nameChangeCertificateNumber", e.target.value)
              }
              required
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography
              style={{
                color: "black",
                fontWeight: "bold",
                textAlign: "end",
                marginTop: "17px",
              }}
            >
              Certificate Issued Date
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <DatePicker
              popupStyle={{ width: "300px", height: "150px" }}
              value={
                props.formdata.NameChangeDetails[0].nameChangeIssueDate
                  ? dayjs(
                      props.formdata.NameChangeDetails[0].nameChangeIssueDate,
                      "DD/MM/YYYY"
                    )
                  : null
              }
              onChange={(date, dateString) =>
                handleInputChange("nameChangeIssueDate", dateString)
              }
              format="DD/MM/YYYY"
              style={{ marginTop: "10px", width: "240px" }}
            />
          </Grid>
        </Grid>

        {/* Grid 3: Navigation Button Row*/}
        <Grid container spacing={1} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Grid item xs={6} md={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handlePrevClick}
            >
              {" "}
              Prev{" "}
            </Button>
          </Grid>

          <Grid item xs={6} md={2}>
            <Button
              variant="contained"
              color="primary"
              disabled={!hasValidValues()}
              onClick={handleNextClick}
            >
              {" "}
              Next{" "}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Fragment>
  );
};

export default NameChangeDetails;
