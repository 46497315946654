import React, { useState, useEffect, Fragment, useRef } from "react";

import { Tooltip } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  CardContent,
  Grid,
  Button,
  Typography,
  TextField,
} from "@mui/material";

import UploadComponent from "../../utilityComponents/UploadComponent";

const AdditionalDetails = (props) => {
  const hiddenFileInput = useRef(null);
  const [renderCount, setRenderCount] = useState(0);
  const [mappedCheckList, setMappedChecklist] = useState([]);
  const [imgDocumentData, setImgDocumentData] = useState([]);
  const [details, setDetails] = useState(
    props.formdata?.AdditionalDetails || [
      {
        documentKey: "",
        ImageURI: [],
      },
    ]
  );

  const mapCheckList = () => {
    const names = {
      UANVerification: "UAN Id Card",
      AddressVerification: "Proof of Address",
      "Form26-AS Verification": "Form26-AS",
      Form16Verification: "Form16",
      IdentityVerification: "Passport / Driving License / Voter ID",
      BankStatementVerification: "Bank Statement",
      OfficialNameChangeVerification: "Official Name Change (Affidavit)",
      CompanyCheckVerification: "Company Documents (Address proof of company)",
      DirectorshipVerification: "Company Supporting Documents for Director",
    };

    if (
      props &&
      props.formdata &&
      props.formdata.checkList[0] &&
      !(
        props.formdata.AdditionalDetails &&
        Array.isArray(props.formdata.AdditionalDetails) &&
        props.formdata.AdditionalDetails.length > 0 &&
        props.formdata.AdditionalDetails[0].documentKey !== ""
      )
    ) {
      const list = props.formdata.checkList[0]
        .map((item) => names[item])
        .filter((item) => item !== undefined);

      const value = list.map((check, index) => {
        return {
          documentKey: check,
          ImageURI: [],
          isText: true,
        };
      });

      setDetails([...value]);
    } else {
      console.log("value", props.formdata.AdditionalDetails);
    }
  };

  const handleAddEntry = () => {
    setDetails((prevState) => [
      ...prevState,
      {
        documentKey: "",
        ImageURI: [],
      },
    ]);
  };

  const handleDeleteEntry = (index) => {
    if (details.length === mappedCheckList.length) {
      return; // Do not allow deleting the last entry
    }
    setDetails((details) => details.filter((_, i) => i !== index));
  };

  const handleInputChange = (index, field, value) => {
    setDetails((prevState) => {
      const newState = [...prevState];
      newState[index][field] = value;
      return newState;
    });
  };

  const handleShowLabel = (index, field, value) => {
    setDetails((prevState) => {
      const newState = [...prevState];

      newState[index]["isText"] = true;
      return newState;
    });
  };

  const hasValidValues = () => {
    for (const adEntry of details) {
      if (adEntry.documentKey === "") {
        return false;
      }
      // Check if a document is uploaded for this entry
      if (adEntry.ImageURI.length < 1) {
        return false;
      }
    }
    return true;
  };

  const handlePrevClick = (e) => {
    e.preventDefault();
    props.prevStep();
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    props.nextStep();
  };

  const handleOnDocumentChange = (target, data, index) => {
    try {
      const { name } = target;
      setDetails((prevState) => {
        const updatedDetails = [...prevState];
        updatedDetails[index]["ImageURI"] = data;
        return updatedDetails;
      });
    } catch {
      setDetails((prevState) => {
        const updatedDetails = [...prevState];
        return updatedDetails;
      });
    }
  };

  const removeImage = (index) => {
    // this is the line that you are looking for
    details.map((data, i) =>
      index === i
        ? setDetails((prevState) => {
            const updatedDetails = [...prevState];
            updatedDetails[index]["ImageURI"] = [];
            return updatedDetails;
          })
        : null
    );
    hiddenFileInput.current.value = null;
  };

  useEffect(() => {
    setRenderCount(1);
    console.log("Eff 1");
    console.log("details", details);
    const page = "AdditionalDetails";
    const value = [...details];
    props.onChangeformvalue(page, value);
    if (
      props.formdata.AdditionalDetails &&
      Array.isArray(props.formdata.AdditionalDetails)
    ) {
      console.log("My AdditionalDetails: ", props.formdata.AdditionalDetails);
      if (details.length > 0) {
        if (!details[0].documentKey) {
          console.log("Ye aya");
          props.nextStep();
        }
      } else if (details.length === 0 && renderCount === 1) {
        props.nextStep();
      }
    }
  }, [details]);

  useEffect(() => {
    console.log("props.formdata.checkList[0]", props.formdata.checkList[0]);
    const runEffect = async () => {
      await mapCheckList();
    };
    console.log("Eff 2");
    runEffect();
  }, [props.formdata.checkList[0]]);

  return (
    <Fragment>
      <CardContent>
        {/* Grid 1 */}
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" fontWeight="bold">
              Additional Documents
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddEntry}
              >
                Add Entry
              </Button>
            </Box>
          </Grid>
        </Grid>

        {/* Grid 2 */}

        <Grid
          container
          spacing={1}
          marginTop={"20px"}
          maxHeight={"325px"}
          style={{ overflow: "auto" }}
        >
          {details.map((entry, index) => (
            <>
              <Grid item xs={14} md={6} container alignItems="start">
                {props.formdata.AdditionalDetails?.[index]?.documentKey &&
                props.formdata.AdditionalDetails?.[index]?.isText ? (
                  <Typography variant="h6" fontWeight="bold">
                    {props.formdata.AdditionalDetails?.[index]?.documentKey}
                  </Typography>
                ) : (
                  <TextField
                    label="Document Name"
                    variant="outlined"
                    fullWidth
                    value={
                      props.formdata.AdditionalDetails?.[index]?.documentKey
                    }
                    onBlur={(e) => handleShowLabel(index, "documentKey")}
                    onChange={(e) =>
                      handleInputChange(index, "documentKey", e.target.value)
                    }
                    required
                  ></TextField>
                )}
              </Grid>

              <Grid item xs={6} md={2}>
                <UploadComponent
                  id={"uploadImgDocument" + index}
                  name="ImageURI"
                  isDraggable={false}
                  // isOnlyImg={true}
                  maxAcceptedSize={4}
                  documentData={entry.ImageURI}
                  setDocumentData={setImgDocumentData}
                  handleOnChange={(target, data) => {
                    handleOnDocumentChange(target, data, index);
                  }}
                />
              </Grid>

              <Grid item xs={2} md={1.5}>
                {props.formdata.AdditionalDetails?.[index]?.documentKey ? (
                  <Typography></Typography>
                ) : (
                  <Button
                    id={`${index}`}
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDeleteEntry(index)}
                  >
                    Delete
                  </Button>
                )}
              </Grid>

              <Grid item xs={2} md={2.5}>
                {props.formdata.AdditionalDetails?.[index]?.ImageURI?.length >
                  0 && (
                  <div>
                    <Tooltip
                      placement="bottom"
                      title={`Remove all uploaded files for ${props.formdata.AdditionalDetails?.[index]?.documentKey}`}
                      color={"#ff0000"}
                      key={"#ff0000"}
                    >
                      <button onClick={() => removeImage(index)}>
                        <DeleteIcon sx={{ color: "red" }} />
                      </button>
                    </Tooltip>
                  </div>
                )}
              </Grid>
            </>
          ))}
        </Grid>

        {/* Grid 3 */}
        <Grid container spacing={1} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Grid item xs={4} md={2.4}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handlePrevClick}
            >
              {" "}
              Prev{" "}
            </Button>
          </Grid>
          <Grid item xs={4} md={1.5}>
            <Button
              variant="contained"
              color="primary"
              disabled={!hasValidValues()}
              onClick={handleNextClick}
            >
              {" "}
              Next{" "}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Fragment>
  );
};

export default AdditionalDetails;
