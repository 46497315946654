import React, { Fragment } from "react";
import { CardContent, Box } from "@mui/material";
import Typography from "@mui/material/Typography";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CustomButton from "../../components/CustomButton";
import { getLocalStorageItem } from "../../helpers/LocalStorage";

const EmailSent = (props) => {
  const handleCNCClick = (e) => {
    props.resetStep();
  };

  const refresh = () => window.location.reload(true);

  return (
    <Fragment>
      {getLocalStorageItem("loginRole") === "4" ? (
        <CardContent>
          <Box sx={{ textAlign: "center", my: 17 }}>
            <CheckCircleIcon sx={{ mb: 1, color: "#58E800", fontSize: 90 }} />
            <Typography variant="h4" sx={{ fontWeight: 500 }}>
              {" "}
              Email Sent to Candidate with Temporary Credentials
            </Typography>
          </Box>

          <Box
            sx={{
              my: { xs: 0, sm: -1, md: -2, lg: -2 },
              mr: { md: 12, lg: 18 },
              display: { xs: "flex" },
              justifyContent: { xs: "space-around", sm: "flex-end" },
              gap: { xs: 3, sm: 3, md: 6, lg: 55.5 },
            }}
          >
            <CustomButton
              buttonName={"Create New Case"}
              onClick={handleCNCClick}
            />
            <CustomButton buttonName={"Done"} onClick={refresh} />
          </Box>
        </CardContent>
      ) : (
        <CardContent>
          <Box sx={{ textAlign: "center", my: 17 }}>
            <CheckCircleIcon sx={{ mb: 1, color: "#58E800", fontSize: 90 }} />
            <Typography variant="h4" sx={{ fontWeight: 500 }}>
              {" "}
              Case Created Successfully.
            </Typography>
          </Box>

          <Box
            sx={{
              my: { xs: 0, sm: -1, md: -2, lg: -2 },
              mr: { md: 12, lg: 18 },
              display: { xs: "flex" },
              justifyContent: { xs: "space-around", sm: "flex-end" },
              gap: { xs: 3, sm: 3, md: 6, lg: 55.5 },
            }}
          >
            <CustomButton buttonName={"Done"} onClick={refresh} />
          </Box>
        </CardContent>
      )}
    </Fragment>
  );
};

export default EmailSent;
