import React, { useState, useEffect, Fragment } from "react";

import { DatePicker } from "antd";
import {
  Box,
  CardContent,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";

const BankDetails = (props) => {
  const { RangePicker } = DatePicker;
  const defaultValue = props.formData?.BankDetails?.[0]?.statementPeriod
    ? props.formData.BankDetails[0].statementPeriod
    : null;
  const [details, setDetails] = useState(
    props.formdata?.BankDetails || [
      {
        bankCompName: "",
        bankCompLocation: "",
        bankName: "",
        bankBranch: "",
        statementPeriod: "",
        ImageURI: "",
      },
    ]
  );

  const handleInputChange = (field, value) => {
    setDetails((prevState) => {
      const newState = [...prevState];
      newState[0][field] = value;
      return newState;
    });
  };

  const hasValidValues = () => {
    for (const bankEntry of details) {
      if (
        bankEntry.bankCompanyName === "" ||
        bankEntry.bankCompanyLocation === "" ||
        bankEntry.bankName === "" ||
        bankEntry.bankBranch === "" ||
        bankEntry.bankStatementPeriod === ""
      ) {
        return false;
      }
      // if (!bankEntry.ImageURI) {
      //   return false;
      // }
    }
    return true;
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    props.nextStep();
  };

  const handlePrevClick = (e) => {
    e.preventDefault();
    props.prevStep();
  };

  useEffect(() => {
    const page = "BankDetails";
    const value = [...details];
    console.log("handleSubmit event BankDetails: ", page, value);
    props.onChangeformvalue(page, value);
  }, [details]);

  return (
    <Fragment>
      <CardContent>
        {/* Grid 1: Header Row */}
        <Grid container>
          <Grid item xs={12} md={7}>
            <Typography
              variant="h6"
              style={{ fontSize: "x-large", fontWeight: "bold" }}
            >
              Bank Statement Details
            </Typography>
          </Grid>
          {/* <Grid item xs={12} md={5}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                // onClick={handleAddEntry}
              >
                Upload Document
              </Button>
            </Box>
          </Grid> */}
        </Grid>

        {/* Grid 2: Form Field */}
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Company Name"
              variant="outlined"
              fullWidth
              value={props.formdata.BankDetails?.[0]?.bankCompName}
              onChange={(e) =>
                handleInputChange("bankCompName", e.target.value)
              }
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Company Location"
              variant="outlined"
              fullWidth
              value={props.formdata.BankDetails?.[0]?.bankCompLocation}
              onChange={(e) =>
                handleInputChange("bankCompLocation", e.target.value)
              }
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Bank Name"
              variant="outlined"
              fullWidth
              value={props.formdata.BankDetails?.[0]?.bankName}
              onChange={(e) => handleInputChange("bankName", e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Bank Branch"
              variant="outlined"
              fullWidth
              value={props.formdata.BankDetails?.[0]?.bankBranch}
              onChange={(e) => handleInputChange("bankBranch", e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Statement Period (Start Date DD/MM/YYYY - DD/MM/YYYY End Date) "
              variant="outlined"
              fullWidth
              value={props.formdata.BankDetails?.[0]?.bankStatementPeriod}
              onChange={(e) =>
                handleInputChange("bankStatementPeriod", e.target.value)
              }
              required
            />
          </Grid>

          {/* <Grid item xs={12} md={2}>
            <Typography
              style={{
                color: "black",
                fontWeight: "bold",
                textAlign: "left",
                marginTop: "17px",
              }}
            >
              Statement Period
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <RangePicker
              cellRender={(current) => {
                const style = {};
                if (current.date() === 1) {
                  style.border = "1px solid #1890ff";
                  style.borderRadius = "50%";
                }
                return (
                  <div className="ant-picker-cell-inner" style={style}>
                    {current.date()}
                  </div>
                );
              }}
            />
          </Grid> */}
        </Grid>

        {/* Grid 3: Navigation Button Row*/}
        <Grid container spacing={1} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Grid item xs={6} md={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handlePrevClick}
            >
              {" "}
              Prev{" "}
            </Button>
          </Grid>

          <Grid item xs={6} md={2}>
            <Button
              variant="contained"
              color="primary"
              disabled={!hasValidValues()}
              onClick={handleNextClick}
            >
              {" "}
              Next{" "}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Fragment>
  );
};

export default BankDetails;
