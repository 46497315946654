import React, { Component } from "react";
// import Dialog from "@material-ui/core/Dialog";
// import AppBar from "@material-ui/core/AppBar";
// import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import Button from "@mui/material/Button";

const Success = (props) => {
  const continuee = (e) => {
    e.preventDefault();
    // PROCESS FORM //
    props.setprevStep();
  };
  const back = (e) => {
    e.preventDefault();
    props.prevStep();
  };

  return (
    // <MuiThemeProvider>
    <>
      {/* <Dialog open fullWidth maxWidth="sm">
          <AppBar title="Success" /> */}
      <h1>Thank You For Your Submission</h1>
      <p>You will get an email with further instructions.</p>
      {/* </Dialog> */}
    </>
  );
};

export default Success;
