import { setError } from "../store/error/errorSlice";
import axios from "axios";

export const getRequests = async (onSuccess, dispatch, url) => {
  return await axios
    .get(url, {
      responseType: "json",
      withCredentials: true,
    })
    .then((res) => {
      return onSuccess(res);
    })
    .catch((e) => {
      dispatch(
        setError(["An Error occured while fetching record data! ", e, false])
      );
    });
};

export const postRequests = async (
  onSuccess,
  dispatch,
  payload,
  url,
  headers = {}
) => {
  return await axios
    .post(url, payload, {
      withCredentials: true,
      headers: headers,
    })
    .then((res) => {
      onSuccess(res);
    })
    .catch((e) => {
      console.log(e);
      dispatch(setError(["An Error occured while creating record!", e, false]));
    });
};

export const patchRequests = async (dispatch, payload, url) => {
  await axios
    .patch(url, payload, {
      withCredentials: true,
    })
    .then((res) => {})
    .catch((e) => {
      dispatch(
        setError(["An Error occured while updating record data! ", e, false])
      );
    });
};

export const deleteRequests = async (dispatch, url) => {
  await axios
    .delete(url, {
      withCredentials: true,
    })
    .then((res) => {
      console.log(res);
    })
    .catch((e) => {
      dispatch(
        setError(["An Error occured while deleting record data! ", e, false])
      );
    });
};
