import React, { Fragment, useEffect, useState, useRef } from "react";

import { Modal, Tooltip } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/Upload";
import {
  Box,
  CardContent,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import UploadComponent from "../../utilityComponents/UploadComponent";

const EmploymentDetails = (props) => {
  const hiddenFileInput = useRef(null);
  const [imgDocumentData, setImgDocumentData] = useState([]);
  const [details, setDetails] = useState(
    props.formdata?.EmploymentDetails || [
      {
        orgName: "",
        designation: "",
        empId: "",
        tenure: "",
        manager: "",
        salary: "",
        reason: "",
        ImageURI: [],
      },
    ]
  );

  const info = (empEntry, index) => {
    Modal.info({
      title: "Upload Employment Documents from below list only",
      content: (
        <div>
          <p style={{ fontWeight: "bold" }}>Current Employment: </p>{" "}
          <p>
            Resignation Acceptance Email or Letter / Offer Letter / Appointment
            Letter & Payslips
          </p>
          <p style={{ fontWeight: "bold" }}>Previous Employments: </p>{" "}
          <p>Reliving Letters & Service Letters / Experience Letters</p>
          <br />
        </div>
      ),
      footer: [
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ color: "red", fontWeight: "bold" }}>
            {" "}
            ** File size should be less than 4MB.{" "}
          </span>
          <div
            style={{
              marginLeft: "auto",
              color: "white",
            }}
          >
            <UploadComponent
              id={"uploadImgDocument" + index}
              name="ImageURI"
              isDraggable={false}
              // isOnlyImg={true}
              maxAcceptedSize={4}
              documentData={empEntry.ImageURI}
              setDocumentData={setImgDocumentData}
              handleOnChange={(target, data) => {
                Modal.destroyAll();
                handleOnDocumentChange(target, data, index);
              }}
            />
          </div>
        </div>,
      ],
      width: 600,
    });
  };

  const handleInputChange = (index, field, value) => {
    setDetails((prevState) => {
      const newState = [...prevState];
      newState[index][field] = value;
      return newState;
    });
  };

  const handleAddEntry = () => {
    setDetails((prevState) => [
      ...prevState,
      {
        orgName: "",
        designation: "",
        empId: "",
        tenure: "",
        manager: "",
        salary: "",
        reason: "",
        ImageURI: [],
      },
    ]);
  };

  const handleDeleteEntry = (index) => {
    if (details.length === 1) {
      return; // Do not allow deleting the last entry
    }
    setDetails((details) => details.filter((_, i) => i !== index));
  };

  const hasValidValues = () => {
    for (const empEntry of details) {
      if (
        empEntry.orgName === "" ||
        empEntry.designation === "" ||
        empEntry.empId === "" ||
        empEntry.tenure === "" ||
        empEntry.manager === "" ||
        empEntry.salary === "" ||
        empEntry.reason === ""
      ) {
        return false;
      }
      // Check if a document is uploaded for this entry
      if (!empEntry.ImageURI.length > 0) {
        return false;
      }
    }
    return true;
  };

  const handlePrevClick = (e) => {
    e.preventDefault();
    props.prevStep();
  };

  const handleEmpDetailsSaveClick = () => {
    console.log("Entries: ", details);
    console.log("Values: ", hasValidValues());
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    props.nextStep();
  };

  const handleOnDocumentChange = (target, data, index) => {
    try {
      const { name } = target;
      setDetails((prevState) => {
        const updatedDetails = [...prevState];
        updatedDetails[index]["ImageURI"] = data;
        return updatedDetails;
      });
    } catch {
      setDetails((prevState) => {
        const updatedDetails = [...prevState];
        return updatedDetails;
      });
    }
  };

  const removeImage = (index) => {
    // this is the line that you are looking for
    details.map((data, i) =>
      index === i
        ? setDetails((prevState) => {
            const updatedDetails = [...prevState];
            updatedDetails[index]["ImageURI"] = [];
            return updatedDetails;
          })
        : null
    );
    hiddenFileInput.current.value = null;
  };

  useEffect(() => {
    const page = "EmploymentDetails";
    const value = [...details];
    console.log("handleSubmit eveent EmploymentDetails", page, value);
    props.onChangeformvalue(page, value);
  }, [details]);

  return (
    <Fragment>
      <CardContent>
        {/* Grid 1 */}
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              style={{ fontSize: "x-large", fontWeight: "bold" }}
            >
              Employment Details
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddEntry}
              >
                Add Entry
              </Button>
            </Box>
          </Grid>
        </Grid>

        {/* Grid 2 */}
        <div style={{ height: "360px", overflow: "auto" }}>
          {details.map((empEntry, index) => (
            <>
              <Grid container spacing={1} key={index}>
                {index > 0 ? (
                  <Grid item xs={24} md={11} sx={{ mt: 4 }}>
                    <TextField
                      label="Organisation Name"
                      variant="outlined"
                      fullWidth
                      value={props.formdata.EmploymentDetails?.[index]?.orgName}
                      onChange={(e) =>
                        handleInputChange(index, "orgName", e.target.value)
                      }
                      required
                    />
                  </Grid>
                ) : (
                  <Grid item xs={24} md={11} sx={{ mt: 2 }}>
                    <TextField
                      label="Organisation Name"
                      variant="outlined"
                      fullWidth
                      value={props.formdata.EmploymentDetails?.[index]?.orgName}
                      onChange={(e) =>
                        handleInputChange(index, "orgName", e.target.value)
                      }
                      required
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={5}>
                  <TextField
                    label="Designation"
                    variant="outlined"
                    fullWidth
                    value={empEntry.designation}
                    onChange={(e) =>
                      handleInputChange(index, "designation", e.target.value)
                    }
                    required
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <TextField
                    label="Employee id"
                    variant="outlined"
                    fullWidth
                    value={empEntry.empId}
                    onChange={(e) =>
                      handleInputChange(index, "empId", e.target.value)
                    }
                    required
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <TextField
                    label="Tenure ( Start & End Date in DD/MM/YYYY )"
                    variant="outlined"
                    fullWidth
                    value={empEntry.tenure}
                    onChange={(e) =>
                      handleInputChange(index, "tenure", e.target.value)
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    label="Manager"
                    variant="outlined"
                    fullWidth
                    value={empEntry.manager}
                    onChange={(e) =>
                      handleInputChange(index, "manager", e.target.value)
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Salary"
                    variant="outlined"
                    fullWidth
                    value={empEntry.salary}
                    onChange={(e) =>
                      handleInputChange(index, "salary", e.target.value)
                    }
                    required
                  />
                </Grid>
                <Grid item xs={24} md={11}>
                  <TextField
                    label="Reason for leaving"
                    variant="outlined"
                    fullWidth
                    value={empEntry.reason}
                    onChange={(e) =>
                      handleInputChange(index, "reason", e.target.value)
                    }
                    required
                  />
                </Grid>

                <Grid item xs={5} md={3}>
                  <Button
                    id={`${index}`}
                    variant="contained"
                    color="primary"
                    startIcon={<UploadFileIcon />}
                    onClick={() => {
                      info(empEntry, index);
                    }}
                  >
                    Add Files
                  </Button>
                </Grid>
                <Grid item xs={4} md={3}>
                  {props.formdata.EmploymentDetails?.[index]?.ImageURI?.length >
                    0 && (
                    <div>
                      <Tooltip
                        placement="bottom"
                        title={`Remove all uploaded files for ${props.formdata.EmploymentDetails?.[index]?.orgName}`}
                        color={"#ff0000"}
                        key={"#ff0000"}
                      >
                        <button onClick={() => removeImage(index)}>
                          <DeleteIcon sx={{ color: "red" }} />
                        </button>
                      </Tooltip>
                    </div>
                  )}
                </Grid>

                <Grid item xs={3} md={5}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDeleteEntry(index)}
                      style={{ marginLeft: "auto" }}
                    >
                      Delete
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </>
          ))}
        </div>

        {/* Grid 3 */}
        <Grid container spacing={1} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Grid item xs={12} md={8}>
            <Typography style={{ color: "red", fontWeight: "bold" }}>
              *Employemnt history should be in reverse chronological order i.e
              latest first.
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handlePrevClick}
            >
              {" "}
              Prev{" "}
            </Button>
          </Grid>
          {/* <Grid item xs={3} md={1}>
                        <Button variant="contained" color="secondary" disabled='true'> Cancel </Button>
                    </Grid>
                    <Grid item xs={3} md={1}>
                        <Button variant="contained" color="primary" onClick={handleEmpDetailsSaveClick}> Save </Button>
                    </Grid> */}
          <Grid item xs={6} md={2}>
            <Button
              variant="contained"
              color="primary"
              disabled={!hasValidValues()}
              onClick={handleNextClick}
            >
              {" "}
              Next{" "}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Fragment>
  );
};
export default EmploymentDetails;
