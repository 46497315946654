import { Chip, TableRow } from "@mui/material";
import React from "react";
import { CustomTable, StyledTableCell, StyledTableRow } from "./CustomTable";
import { tableTheme, colorTheme } from "../themes/theme";
import TableActions from "./TableActions";

//Custom Reusable Table
//headings is Array of heading(Strings)
//tableBody is Array of Objects, with heading as keys
//actions is Array of Objects, eg: [{name:"delete",onClick:func}]
//onRowClick is function
//Chip is created based on Status heading and values

const CustomDataTable = ({
  headings,
  tableBody,
  actions,
  status,
  onRowClick,
}) => {
  const chipColor = (status) => {
    if (status === "Active" || status === "Sent")
      return {
        bgcolor: colorTheme.palette.statusGreenBg,
        color: colorTheme.palette.statusGreenBorder,
        borderColor: colorTheme.palette.statusGreenBorder,
      };
    else if (status === "Pending" || status === "Inactive")
      return {
        bgcolor: colorTheme.palette.statusRedBg,
        color: colorTheme.palette.statusRedBorder,
        borderColor: colorTheme.palette.statusRedBorder,
      };
  };
  const tableHeader = () => {
    return headings.map(
      (
        heading,
        index //Creating Table headings
      ) => (
        <StyledTableCell key={heading} align={index === 0 ? "left" : "right"}>
          {heading}
        </StyledTableCell>
      )
    );
  };
  const tableBodyContent = () => {
    return tableBody.map(
      (
        row,
        rowIndex //Creating Table Rows
      ) => (
        <StyledTableRow key={rowIndex} onClick={onRowClick}>
          {headings.map((heading, index) =>
            heading === "Status" ? (
              <StyledTableCell
                key={heading + index}
                align={index === 0 ? "left" : "right"}
              >
                <Chip
                  label={row[heading]}
                  variant="outlined"
                  sx={chipColor(row[heading])}
                />
              </StyledTableCell>
            ) : (
              heading !== "Actions" && (
                <StyledTableCell
                  key={heading + index}
                  align={index === 0 ? "left" : "right"}
                >
                  {row[heading]}
                </StyledTableCell>
              )
            )
          )}
          {actions !== undefined && (
            <StyledTableCell align="right">
              {actions.map(
                (
                  action //Creating all actions for each row
                ) => (
                  <TableActions
                    key={action.name}
                    action={action}
                    row={row}
                    rowIndex={rowIndex}
                  />
                )
              )}
            </StyledTableCell>
          )}
        </StyledTableRow>
      )
    );
  };
  return (
    <CustomTable headContent={tableHeader()} bodyContent={tableBodyContent()} />
  );
};

export default CustomDataTable;
