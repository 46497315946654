import { DatePicker, Form, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, Fragment, useContext } from "react";

import dayjs from "dayjs";
import validator from "validator";
import {
  CardContent,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";

import ComboField from "../../components/ComboField";
import {
  getClientSpecificByCode,
  getClientSpecific,
  getClientSpecificList,
} from "../../services/clientServices";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../helpers/LocalStorage";
import AuthContext from "../../contexts/AuthProvider";

const BasicDetails = (props) => {
  const dispatch = useDispatch();
  const [res, setRes] = useState([]);
  const [reset, setReset] = useState(false);
  const [trueKeys, setTrueKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [clientList, setClientList] = useState([]);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [clientCompany, setClientCompany] = useState("");
  const { setLoggedInUserClientCode } = useContext(AuthContext);
  const [details, setDetails] = useState(
    props.formdata?.BasicDetail || [
      {
        applicantCompanyName: "",
        applicantCompanyCode: "",
        applicantEmpId: "",
        applicantName: "",
        applicantEmail: "",
        applicantMobile: "",
        applicantDOJ: "",
        applicantChecks: [],
      },
    ]
  );

  const clientSpecifics = (data) => {
    const keys = Object.keys(data);
    const trueKeys = keys.filter((key) => data[key][0] === "true");
    return trueKeys;
  };

  const hasValidValues = () => {
    for (const appEntry of details) {
      if (
        appEntry.applicantName !== "" &&
        appEntry.applicantEmail !== "" &&
        appEntry.applicantMobile !== "" &&
        appEntry.applicantDOJ !== "" &&
        appEntry.applicantChecks &&
        appEntry.applicantChecks.length > 0 &&
        appEntry.applicantChecks[0] !== "" &&
        isValidEmail
      ) {
        return true;
      }
    }
    return false;
  };

  const handleSelectedItemsChange = (event) => {
    // set applicantChecksHere
    handleInputChange(0, "applicantChecks", event);
  };

  const handleInputChange = (index, field, value) => {
    if (field === "applicantCompanyName") {
      setClientCompany(value);
      setReset(true);
      props.formdata.BasicDetail[0].applicantChecks = [];
    }
    if (field === "applicantEmail") {
      setIsValidEmail(validator.isEmail(value));
    }
    setDetails((prevState) => {
      const newState = [...prevState];
      newState[index][field] = value;
      return newState;
    });
  };

  const handleDateChange = (index, key, date) => {
    setDetails((prevState) => {
      const updatedDetails = [...prevState];
      updatedDetails[index][key] = date ? date : dayjs();
      return updatedDetails;
    });
  };

  const handleCodeSpecificSuccess = (res) => {
    console.log("Code Specific: ", res);
    setClientCompany(res.data.data.ClientName);
    setRes(res.data);
  };

  const handleNameSpecificSuccess = (response) => {
    props.formdata.BasicDetail[0].applicantCompanyCode =
      response.data.data.ClientCode;
    setLocalStorageItem("loginClientCode", response.data.data.ClientCode);
    setRes(response.data);
    setIsLoading(false);
  };

  const onCSLSuccess = (res1) => {
    const options = res1.data.data.map((name, index) => ({
      value: String(index + 1),
      label: name,
    }));
    setClientList(options);
  };

  const handlePrevClick = (e) => {
    e.preventDefault();
    props.prevStep();
  };

  const handlePDSaveClick = () => {
    console.log("Api Res: ", res.data);
    console.log("True Keys: ", trueKeys);
  };

  const handleNextClick = (e) => {
    console.log("Final: ", props.formdata.BasicDetail[0]);
    e.preventDefault();
    props.nextStep();
  };

  useEffect(() => {
    const page = "BasicDetail";
    const value = [...details];
    props.onChangeformvalue(page, value);
  }, [details]);

  useEffect(() => {
    if (details[0].applicantChecks && res.data) {
      setTrueKeys(clientSpecifics(res.data));
    }
  }, [details, res.data]);

  useEffect(() => {
    if (getLocalStorageItem("loginRole") === "4") {
      console.log("In Use Effect: IF");
      console.log("Basic Details: ", props.formdata?.BasicDetail?.[0]);
      getClientSpecificByCode(
        handleCodeSpecificSuccess,
        dispatch,
        getLocalStorageItem("loginClientCode")
      );
    } else if (
      props.formdata?.BasicDetail?.[0]?.applicantCompanyName &&
      props.formdata?.BasicDetail?.[0]?.applicantCompanyName !== ""
    ) {
      console.log("In Use Effect: ELSE IF");
      console.log("Basic Details: ", props.formdata.BasicDetail[0]);

      getClientSpecificList(onCSLSuccess, dispatch);
      getClientSpecific(
        handleNameSpecificSuccess,
        dispatch,
        props.formdata.BasicDetail[0].applicantCompanyName
      );
    } else {
      console.log("In Use Effect: ELSE");
      getClientSpecificList(onCSLSuccess, dispatch);
    }
  }, []);

  useEffect(() => {
    if (
      clientCompany &&
      (getLocalStorageItem("loginRole") === "2" ||
        getLocalStorageItem("loginRole") === "1")
    ) {
      console.log("In Use Effect: Client Company | IF");
      getClientSpecific(handleNameSpecificSuccess, dispatch, clientCompany);
    } else if (
      props.formdata.BasicDetail?.[0] &&
      getLocalStorageItem("loginRole") === "4"
    ) {
      console.log("In Use Effect: Client Company | ELSE IF");
      props.formdata.BasicDetail[0].applicantCompanyName = clientCompany;
      props.formdata.BasicDetail[0].applicantCompanyCode =
        getLocalStorageItem("loginClientCode");
    }
  }, [clientCompany]);

  return (
    <Fragment>
      <CardContent>
        {/* Grid 1 */}
        <Grid container style={{ marginBottom: "5px" }}>
          <Grid item xs={10} md={6.1}>
            <Typography
              variant="h6"
              style={{ fontSize: "x-large", fontWeight: "bold" }}
            >
              Applicant Basic Details
            </Typography>
          </Grid>

          {getLocalStorageItem("loginRole") === "2" ||
          getLocalStorageItem("loginRole") === "1" ? (
            <>
              <Grid item xs={7} md={2}>
                <Typography style={{ fontWeight: "bold", marginTop: "10px" }}>
                  Select Client Company
                </Typography>
              </Grid>
              <Grid item xs={7} md={3}>
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Search to Select Client"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={clientList}
                  value={
                    props.formdata?.BasicDetail?.[0].applicantCompanyName
                      ? props.formdata.BasicDetail[0].applicantCompanyName
                      : null
                  }
                  onChange={(e, value) =>
                    handleInputChange(0, "applicantCompanyName", value.label)
                  }
                />
              </Grid>
            </>
          ) : null}
        </Grid>

        {/* Grid 2 */}
        <div style={{ height: "360px", overflow: "auto" }}>
          {details.map((gapEntry, index) => (
            <>
              <Grid
                container
                spacing={2}
                style={{ marginBottom: "10px", marginTop: "5px" }}
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    id="applicantEmpId"
                    label="Client ID / Emp ID"
                    variant="outlined"
                    fullWidth
                    value={props.formdata.BasicDetail?.[index]?.applicantEmpId}
                    onChange={(e) =>
                      handleInputChange(index, "applicantEmpId", e.target.value)
                    }
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    id="applicantName"
                    label="Applicant Name"
                    variant="outlined"
                    fullWidth
                    value={props.formdata.BasicDetail?.[index]?.applicantName}
                    onChange={(e) =>
                      handleInputChange(index, "applicantName", e.target.value)
                    }
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    id="applicantEmail"
                    error={!isValidEmail}
                    label="Applicant Email"
                    variant="outlined"
                    fullWidth
                    value={props.formdata.BasicDetail?.[index]?.applicantEmail}
                    onChange={(e) =>
                      handleInputChange(index, "applicantEmail", e.target.value)
                    }
                    required
                    helperText={!isValidEmail ? "Email is not valid" : null}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    id="applicantMobile"
                    label="Applicant Mobile Number"
                    variant="outlined"
                    fullWidth
                    value={props.formdata.BasicDetail?.[index]?.applicantMobile}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        "applicantMobile",
                        e.target.value
                      )
                    }
                    required
                  />
                </Grid>

                <Grid item xs={6} md={2}>
                  <Typography
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      marginTop: "10px",
                    }}
                  >
                    Applicant Joining Date
                  </Typography>
                </Grid>

                <Grid item xs={6} md={4}>
                  <Form>
                    <Form.Item>
                      <DatePicker
                        popupStyle={{ width: "300px", height: "150px" }}
                        // defaultValue={dayjs()}
                        value={
                          props.formdata.BasicDetail?.[index]?.applicantDOJ
                            ? dayjs(
                                props.formdata.BasicDetail?.[index]
                                  ?.applicantDOJ,
                                "DD/MM/YYYY"
                              )
                            : null
                        }
                        onChange={(date, dateString) =>
                          handleDateChange(0, "applicantDOJ", dateString)
                        }
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>
                  </Form>
                </Grid>

                <Grid item xs={12} md={6}>
                  <ComboField
                    reset={reset}
                    setReset={setReset}
                    options={trueKeys}
                    onChange={handleSelectedItemsChange}
                    selectedItemsProp={
                      props.formdata.BasicDetail?.[index]?.applicantChecks
                        ? props.formdata.BasicDetail?.[index]?.applicantChecks
                        : []
                    }
                  />
                </Grid>
              </Grid>
            </>
          ))}
        </div>

        {/* Grid 3 */}
        <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={!hasValidValues()}
              onClick={handleNextClick}
              style={{ maxWidth: "120px", minWidth: "120px" }}
            >
              {" "}
              Next{" "}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Fragment>
  );
};

export default BasicDetails;
