import React, { useEffect, useState } from "react";
import { Chip, Input, TableRow } from "@mui/material";

import { CustomTable } from "../../components/CustomTable";
import { getLocalStorageItem } from "../../helpers/LocalStorage";
import TableActions from "../../components/TableActions";
import {
  StyledTableCell,
  StyledTableRow,
  colorTheme,
} from "../../themes/theme";

//Custom Reusable Table
//headings is Array of heading(Strings)
//tableBody is Array of Objects, with heading as keys
//actions is Array of Objects, eg: [{name:"delete",onClick:func}]
//onRowClick is function
//Chip is created based on Status heading and values

const CustomInputTable = ({
  headings,
  tableBody,
  actions,
  status,
  onRowClick,
}) => {
  const userRole = status;
  const [formState, setFormState] = useState([...tableBody]);
  const manualColumnIds = [
    "Infomate ID",
    "Date of Initiation",
    "Date of Last Close",
    "Report Type",
    "Current Employment Date (Doc Receive)",
    "Color Code",
  ];
  const onInputChange = (e, rowIndex, heading) => {
    const prevState = [...formState];
    prevState[rowIndex][heading] = e.target.value;
    setFormState([...prevState]);
  };
  const chipColor = (status) => {
    if (status === "Active" || status === "Sent")
      return {
        bgcolor: colorTheme.palette.statusGreenBg,
        color: colorTheme.palette.statusGreenBorder,
        borderColor: colorTheme.palette.statusGreenBorder,
      };
    else if (status === "Pending" || status === "Inactive")
      return {
        bgcolor: colorTheme.palette.statusRedBg,
        color: colorTheme.palette.statusRedBorder,
        borderColor: colorTheme.palette.statusRedBorder,
      };
  };
  const tableHeader = () => {
    return headings.map(
      (
        heading,
        index //Creating Table headings
      ) => (
        <StyledTableCell
          sx={{ padding: "5px", fontSize: "12px" }}
          key={heading}
          align={index === 0 ? "left" : "right"}
        >
          {heading}
        </StyledTableCell>
      )
    );
  };
  const tableBodyContent = () => {
    return formState.map(
      (
        row,
        rowIndex //Creating Table Rows
      ) => (
        <StyledTableRow key={rowIndex} onClick={onRowClick}>
          {headings.map((heading, index) =>
            heading === "Status" ? (
              <StyledTableCell
                sx={{ padding: "2px" }}
                key={heading + index}
                align={index === 0 ? "left" : "right"}
              >
                <Chip
                  label={row[heading]}
                  variant="outlined"
                  sx={chipColor(row[heading])}
                />
              </StyledTableCell>
            ) : (
              heading !== "Actions" && (
                <StyledTableCell
                  key={heading + index}
                  align={index === 0 ? "left" : "right"}
                  sx={{ padding: "2px", fontSize: "12px" }}
                >
                  {(userRole === "2" || userRole === "1") &&
                  manualColumnIds.includes(heading) ? (
                    <Input
                      id={heading + index}
                      sx={{ width: "90%", fontSize: "12px" }}
                      type={
                        heading.toLowerCase().includes("date") ? "date" : "text"
                      }
                      value={formState[rowIndex][heading]}
                      onChange={(e) => {
                        onInputChange(e, rowIndex, heading);
                      }}
                    ></Input>
                  ) : (
                    row[heading]
                  )}
                </StyledTableCell>
              )
            )
          )}
          {actions !== undefined && (
            <StyledTableCell align="right" sx={{ padding: "5px" }}>
              {actions.map(
                (
                  action //Creating all actions for each row
                ) => (
                  <TableActions
                    key={action.name}
                    action={action}
                    row={row}
                    rowIndex={rowIndex}
                  />
                )
              )}
            </StyledTableCell>
          )}
        </StyledTableRow>
      )
    );
  };
  return (
    <CustomTable headContent={tableHeader()} bodyContent={tableBodyContent()} />
  );
};

export default CustomInputTable;
