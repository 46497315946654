import React from "react";
import html2pdf from "html2pdf.js";

export const pdfConverter = async (html) => {
  const opt = {
    margin: 0,
    filename: "output.pdf",
    image: { type: "jpeg", quality: 1 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "in", format: "A4", orientation: "portrait" },
  };
  // html2pdf().set(opt).from(html).save();

  let worker = await html2pdf()
    .set(opt)
    .from(html)
    .toPdf()
    .output("blob")
    .then((data) => {
      return data;
    });

  console.log(worker);

  return worker;
};
