import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { colorTheme, generalTheme } from "../themes/theme";
import { Box, Grid } from "@mui/material";
import SideBar from "./SideBar";
import Ribbon from "./Ribbon";
import CardStyle from "./CardStyle";
import ClientSideBar from "./ClientSideBar";

const Wrapper = ({ title, content, selectedMenuItem, setSelectedMenuItem }) => {
  return (
    <ThemeProvider theme={generalTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box>
          <ClientSideBar
            selectedMenuItem={selectedMenuItem}
            setSelectedMenuItem={setSelectedMenuItem}
          />
        </Box>
        <Box>
          <Grid container spacing={20}>
            <Grid item sx={{ ml: { xs: 0, sm: 0 } }}>
              <Ribbon title={title} />
            </Grid>
          </Grid>

          <CardStyle
            bgColor="#FFFFFF"
            blColor={colorTheme.palette.brightPurple}
            blLeft={15}
            width="75vw"
            height="500px"
            content={content}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default Wrapper;
