import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

const CustomToggleButton = ({ defaultValue, onToggle }) => {
  const [checked, setChecked] = useState(defaultValue);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    onToggle();
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      }
      label="Activate"
    ></FormControlLabel>
  );
};

export default CustomToggleButton;
