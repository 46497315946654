import React from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Chip from "@mui/material/Chip";
import { colorTheme } from "../themes/theme";
import CustomToggleButton from "./CustomToggleButton";
import { SaveAltRounded, SaveAsSharp } from "@mui/icons-material";
import CustomButton from "./CustomButton";

const TableActions = ({ action, row, rowIndex }) => {
  return action.name === "delete" ? (
    <IconButton
      title="Delete"
      key={action.name}
      aria-label="delete"
      sx={{ color: colorTheme.palette.deleteRed }}
      onClick={(e) => {
        action.onClick(e, row, rowIndex);
      }}
    >
      <DeleteIcon />
    </IconButton>
  ) : action.name === "edit" ? (
    <IconButton
      title="Edit"
      key={action.name}
      aria-label="edit"
      sx={{ color: colorTheme.palette.editBlue }}
      onClick={(e) => {
        action.onClick(e, row, rowIndex);
      }}
    >
      <ModeEditIcon />
    </IconButton>
  ) : action.name === "add" ? (
    <IconButton
      title="Add"
      key={action.name}
      aria-label="add"
      sx={{ color: colorTheme.palette.statusGreenBorder }}
      onClick={(e) => {
        action.onClick(e, row, rowIndex);
      }}
    >
      <AddCircleOutlineIcon />
    </IconButton>
  ) : action.name === "toggleDeactivate" ? (
    <Chip
      label={
        <CustomToggleButton
          defaultValue={row["Status"] === "Active" ? true : false}
          onToggle={(e) => action.onClick(e, row, rowIndex)}
        ></CustomToggleButton>
      }
      variant="outlined"
      sx={{
        color: colorTheme.palette.tableTextColor,
        borderColor: colorTheme.palette.editBlue,
      }}
    ></Chip>
  ) : action.name === "save" ? (
    <CustomButton
      buttonName={"Save"}
      onClick={(e) => action.onClick(e, row, rowIndex)}
    />
  ) : (
    <></>
  );
};

export default TableActions;
