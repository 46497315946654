import React from "react";
import { Alert, Snackbar, Typography } from "@mui/material";

const NotificationAlert = (props) => {
  const { notify, setNotify } = props;
  //   const classes = useStyles();
  const handleClose = (event, reason) => {
    setNotify({ ...notify, isOpen: false });
  };
  return (
    <Snackbar
      //   className={classes.root}
      anchorOrigin={{ horizontal: "right", vertical: "top" }}
      open={props.notify.isOpen}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert severity={props.notify.type} onClose={handleClose}>
        <Typography variant="subtitle1" fontWeight="600">
          {props.notify.message}
        </Typography>
      </Alert>
    </Snackbar>
  );
};

export default NotificationAlert;
