import React from "react";
import moment from "moment/moment";

export const DeclarationTemplateFun = (data) => {
  let { sClientCompanyName, sName, imageUrl } = data;
  let declarationHtml = `
  <!DOCTYPE html>
<html>
  <head>
    <title>My PDF Document</title>
    <style>
      body {
        font-family: Arial, sans-serif;
        font-size: 12pt;
        line-height: 1.5;
      }
      table {
        border-collapse: collapse;
        width: 100%;
        /* margin-bottom: 24px; */
      }
      /* th, td {
			padding: 8px;
			text-align: left;
			border: 1px solid #ddd;
		} */
      /* th {
			background-color: #f2f2f2;
			font-weight: bold;
		} */
      @media print {
        /* Add PDF styles here */
        @page {
          size: A4;
          margin: 2cm;
        }
      }
    </style>
  </head>
  <body>
    <!-- <h1>My PDF Document</h1> -->
    <div
      id="declaration-box"
      style="
        width: 206mm;
        height: 292mm;
        display: flex;
        align-items: center;
        flex-direction: column;
      "
    >
      <table style="width: 100%; border-spacing: 0px">
        <tbody>
          <tr>
            <td
              style="width: 100%; border: none; padding: 4%; text-align: center"
            >
              <u
                style="
                  font-family: 'Segoe UI';
                  font-weight: 500;
                  font-size: 14px;
                  color: black;
                "
                >Background Verification Consent and Authorisation</u
              >
            </td>
          </tr>
        </tbody>
      </table>
      <div id="invoice-inner-box" style="width: 94%; padding: 3%">
        <table style="width: 100%; border-spacing: 0px; margin-bottom: 50px">
          <tbody>
            <td style="width: 100%; border: none">
              <label
                style="
                  font-family: 'Segoe UI';
                  font-weight: 500;
                  font-size: 12px;
                  color: black;
                 text-indent: 200px;
                "
                >If employed with <label style="font-weight: 700; font-size: 13px;">${sClientCompanyName}</label>, I agree to
                provide copies of all relevant certificates. I understand that
                employment with <label style="font-weight: 700; font-size: 13px;">${sClientCompanyName}</label>, is governed by
                their employment policies as applicable, including satisfactory
                information from background checks.
                <br />
                <br />
                I hereby certify that all information provided herein, is true
                and complete to the best of my knowledge and belief. I authorize
                the agency to authenticate information I have provided in my
                resume and this Candidate Declaration Form (CDF). To conduct
                enquiries as may be necessary at the company’s discretion, I
                authorize all who may have information relevant to this enquiry
                to disclose it to <label style="font-weight: 700; font-size: 13px;">${sClientCompanyName}</label>, and/or its
                representative. I release all concerned from any liability on
                account of such disclosures.
                <br />
                <br />
                I also declare that the information provided by me in my resume
                and application for employment to <label style="font-weight: 700; font-size: 13px;">${sClientCompanyName}</label>,
                and its representative is authentic and I am liable for all
                inaccuracies and omissions.
                <br />
                <br />
                I promise to extend total co-operation and provide relevant
                documents required.
              </label>
            </td>
          </tbody>
        </table>
        <table style="width: 100%; padding: 2% 2% 0% 2%; border-spacing: 0px">
          <tbody>
            <tr style="text-align: center; margin-bottom: 10px">
              <td style="width: 70%"></td>
              <td style="width: 30%">
                <label>
                  <img
                    width="100px"
                    height="75px"
                    src='${imageUrl}'
                  />
                </label>
                <br /><label
                  style="
                    font-family: 'Segoe UI';
                    font-size: 14px;
                    font-weight: 700;
                  "
                  >Signature :</label
                >
              </td>
            </tr>
          </tbody>
        </table>
        <table style="width: 100%; border-spacing: 0px; margin-bottom: 50px">
          <tbody>
            <tr>
              <td style="width: 10%; font-size: 12px;" >Name :</td>
              <td style="width: 90%; font-size: 12px;" >${sName}</td>
            </tr>
            <tr style="margin-top: 20px">
              <td style="width: 10%; font-size: 12px;">Date :</td>
              <td style="width: 90%; font-size: 12px;">${moment(new Date()).format("DD/MM/YYYY")}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </body>
</html>
  `;

  return declarationHtml;
};
