import "../App.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import { ThemeProvider } from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemButton from "@mui/material/ListItemButton";
import { Button, createTheme, Typography } from "@mui/material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AuthContext from "../contexts/AuthProvider";
import { BASE_URL } from "../services/constants";
import { setError } from "../store/error/errorSlice";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../helpers/LocalStorage";
import { getCookies, deleteCookies } from "../helpers/Cookie";

const drawerWidth = 260;

const generalTheme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),

    fontSize: 12,
    fontWeight: "medium",
  },
  largeIcons: {
    width: 50,
    height: 50,
  },
});

const ClientSideBar = (props) => {
  const { window, setSelectedMenuItem, selectedMenuItem } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [activeComponent, setActiveComponent] = React.useState("dashboard");
  const dispatch = useDispatch();
  const {
    setAuth,
    setLoggedInUserEmail,
    setLoggedInUserRole,
    setLoggedInUserClientCode,
    loggedInUserRole,
  } = useContext(AuthContext);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const handleDashboardClick = () => {
    setActiveComponent("dashboard");
    console.log("Current Dash: ", activeComponent);
  };

  const handleInitiateCaseClick = () => {
    setActiveComponent("newCase");
    console.log("Current Case: ", activeComponent);
  };
  const navigate = useNavigate();
  //Logout handler method
  const handleLogout = () => {
    axios
      .get(`${BASE_URL}auth/logout`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        navigate("/");
        //setAuth(false);
        setAuth(false);
        // setLoggedInUserEmail("");
        // setLoggedInUserRole("");
        // setLoggedInUserClientCode("");
        removeLocalStorageItem("loginRole");
        removeLocalStorageItem("loginEmail");
        removeLocalStorageItem("loginClientCode");
        deleteCookies("DumpValue");
        deleteCookies("Role");

        return response.status;
      })
      .catch((e) => {
        dispatch(setError(["Error in logging Out!", e, false]));
      });
  };

  const handleOnChangeMenu = (e, menuData, c, d) => {
    setSelectedMenuItem(menuData);
    setSelectedMenuId(menuData.id);
  };

  const [menuData, setMenuData] = useState([
    {
      id: 1,
      menuName: "Case Status",
      icon: () => {
        return (
          <HomeOutlinedIcon style={{ fontSize: "23px", marginLeft: "10px" }} />
        );
      },
    },
    {
      id: 2,
      menuName: "Initiate Case",
      icon: () => {
        return (
          <PersonOutlineOutlinedIcon
            style={{ fontSize: "23px", marginLeft: "10px" }}
          />
        );
      },
    },
  ]);
  const [selectedMenuId, setSelectedMenuId] = useState(1);

  useEffect(() => {
    const userRole = getLocalStorageItem("loginRole");
    let menus = [];
    if (userRole === "1" || userRole === "2" || userRole === "4") {
      menus.push(
        {
          id: 1,
          menuName: "Case Status",
          icon: () => {
            return (
              <HomeOutlinedIcon
                style={{ fontSize: "23px", marginLeft: "10px" }}
              />
            );
          },
        },
        {
          id: 2,
          menuName: "Initiate Case",
          icon: () => {
            return (
              <PersonOutlineOutlinedIcon
                style={{ fontSize: "23px", marginLeft: "10px" }}
              />
            );
          },
        }
      );
    } else if (userRole === "4") {
      menus.push({
        id: 1,
        menuName: "Case Status",
        icon: () => {
          return (
            <HomeOutlinedIcon
              style={{ fontSize: "23px", marginLeft: "10px" }}
            />
          );
        },
      });
    } else {
      menus = [];
    }
    setMenuData(menus);
  }, []);

  return (
    <ThemeProvider theme={generalTheme}>
      <Box>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        ></AppBar>
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folders"
        >
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                background: "linear-gradient(#3B2449, #56356A)",
                color: "#fff",
              },
            }}
            open
          >
            <Box>
              <Box>
                <Box
                  component="img"
                  sx={{
                    height: 75,
                    width: 250,
                    border: "2px solid #fff",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                    padding: "2px 30px",
                    marginLeft: "6px",
                    marginTop: "4px",
                  }}
                  alt="Logo"
                  src="https://infomate-trial.s3.amazonaws.com/public_assets/Infomate360+Logo.png"
                />

                <Divider />

                <List component={"nav"}>
                  {menuData &&
                    menuData.map((itm, ind) => {
                      return (
                        <>
                          <ListItem
                            disablePadding
                            sx={
                              itm.id == selectedMenuId
                                ? {
                                    background: "#fff",
                                    color: "#3E264D",
                                    border: "1px solid #fff",
                                    borderRadius: "16px",
                                  }
                                : ""
                            }
                          >
                            <ListItemButton
                              selected
                              onClick={(e) =>
                                handleOnChangeMenu(e, {
                                  id: itm.id,
                                  menuName: itm.menuName,
                                })
                              }
                            >
                              <ListItemIcon>{itm.icon()}</ListItemIcon>
                              <ListItemText primary={itm.menuName} />
                            </ListItemButton>
                          </ListItem>
                        </>
                      );
                    })}
                </List>
              </Box>
            </Box>
            <Box
              p={2}
              mt="auto"
              sx={{ display: "inline-flex", justifyContent: "center" }}
            >
              <Button sx={{ color: "white" }} onClick={handleLogout}>
                <Typography pr={1}>LOGOUT </Typography>
                <LogoutIcon />
              </Button>
            </Box>
            {/* {drawer} */}
          </Drawer>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ClientSideBar;
