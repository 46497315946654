import { postRequests } from "./commonServices";
import { BASE_URL } from "./constants";

export const sendMail = async (onSuccess, dispatch, payload) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  return await postRequests(
    onSuccess,
    dispatch,
    payload,
    `${BASE_URL}email/send`,
    headers
  );
};
