import { useContext } from "react";
import { createSlice } from "@reduxjs/toolkit";
import { AuthProvider } from "../../contexts/AuthProvider";

const initialState = {
  userEmail: "",
  userRole: 0,
  userClientCode: "",
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setRole: (state, action) => {
      state.userRole = action.payload;
    },
    setClientCode: (state, action) => {
      state.userClientCode = action.payload;
    },
  },
});

export const { setEmail, setRole, setClientCode } = userSlice.actions;

export default userSlice.reducer;
