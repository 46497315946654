import { useState, useRef, useEffect } from "react";
import SignaturePad from "react-signature-canvas";
import { Modal, Tabs, Button, message } from "antd";

import ImagePicker from "./ImagePicker";
import { getCookies } from "../helpers/Cookie";
import { getLocalStorageItem } from "../helpers/LocalStorage";

const { TabPane } = Tabs;

const SignatureComponent = (props) => {
  let { visible, onCancel, onSave, imageUrl, setImageUrl } = props;
  const [activeTab, setActiveTab] = useState("");
  const [signature, setSignature] = useState(null);
  const [file, setFile] = useState(null);
  const signaturePad = useRef(null);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handleSignatureClear = () => {
    signaturePad.current.clear();
    setSignature(null);
  };

  const handleSignatureSave = () => {
    if (signaturePad.current.isEmpty()) {
      message.error("Please provide a signature.");
      return;
    }

    const signatureData = signaturePad.current.toDataURL();
    setSignature(signatureData);
    setActiveTab("preview");
  };

  const handleSave = () => {
    if (activeTab === "signature") {
      if (signaturePad.current.isEmpty()) {
        message.error("Please provide a signature.");
        return;
      } else {
        setImageUrl(signature);
        onSave(signature);
      }
    } else if (activeTab === "upload") {
      onSave(file);
    } else if (activeTab === "preview") {
      setImageUrl(signature);
      onSave(signature);
    } else {
      message.error("Please provide a signature or upload a signature file.");
    }
  };

  useEffect(() => {
    console.log("Role: ", getLocalStorageItem("loginRole"));
    if (getLocalStorageItem("loginRole") === "3") {
      setActiveTab("signature");
    } else {
      setActiveTab("upload");
    }
  }, []);

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      onOk={handleSave}
      title="Signature"
    >
      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        {getLocalStorageItem("loginRole") === "3" ? (
          <TabPane tab="Signature" key="signature">
            <SignaturePad
              ref={signaturePad}
              canvasProps={{
                width: 400,
                height: 200,
                className: "signature-canvas",
              }}
            />
            <Button onClick={handleSignatureClear}>Clear</Button>
            <Button type="primary" onClick={handleSignatureSave}>
              Save
            </Button>
          </TabPane>
        ) : null}

        {/* <TabPane tab="Upload" key="upload">
          <Upload.Dragger onChange={handleFileChange}>
            <p className="ant-upload-drag-icon">
              <UploadOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Support for a single upload.</p>
          </Upload.Dragger>
          {file && (
            <>
              <Button onClick={handlePreview} icon={<EyeOutlined />}>
                Preview
              </Button>
              <Button
                onClick={handleFileDelete}
                icon={<DeleteOutlined />}
                danger
              >
                Delete
              </Button>
            </>
          )}
        </TabPane> */}

        <TabPane tab="Upload" key="upload">
          <ImagePicker setImageUrl={setImageUrl} imageUrl={imageUrl} />
        </TabPane>

        {signature ? (
          <TabPane tab="Preview" key="preview">
            <img src={signature} alt="Signature Preview" />
          </TabPane>
        ) : (
          console.log("No Signature", signature)
        )}
      </Tabs>
    </Modal>
  );
};

export default SignatureComponent;
