import React from "react";
import "../App.css";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import BrowserUpdatedOutlinedIcon from "@mui/icons-material/BrowserUpdatedOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItemText from "@mui/material/ListItemText";
import { createTheme, Typography } from "@mui/material";
import { ThemeProvider } from "@emotion/react";

const drawerWidth = 260;

const generalTheme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),

    fontSize: 12,
    fontWeight: "medium",
  },
  largeIcons: {
    width: 50,
    height: 50,
  },
});

const SideBar = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <ThemeProvider theme={generalTheme}>
      <Box>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        ></AppBar>
        <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            <div>
              <Box
                component="img"
                sx={{
                  height: 75,
                  width: 280,
                  backgroundColor: "#fff",
                  padding: "2px 30px",
                }}
                alt="Logo"
                src="https://infomate-trial.s3.amazonaws.com/public_assets/Infomate360+Logo.png"
              />

              <Divider />
              <List>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <HomeOutlinedIcon style={{ color: "#ddd" }} />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <PersonOutlineOutlinedIcon style={{ color: "#ddd" }} />
                    </ListItemIcon>
                    <ListItemText primary="Employee Management" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <PersonPinOutlinedIcon style={{ color: "#ddd" }} />
                    </ListItemIcon>
                    <ListItemText primary="Report Writing" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <PictureAsPdfOutlinedIcon style={{ color: "#ddd" }} />
                    </ListItemIcon>
                    <ListItemText primary="Generate PDF" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <BrowserUpdatedOutlinedIcon style={{ color: "#ddd" }} />
                    </ListItemIcon>
                    <ListItemText primary="Download tracker" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <GroupsOutlinedIcon style={{ color: "#ddd" }} />
                    </ListItemIcon>
                    <ListItemText primary="Client specific" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <CorporateFareOutlinedIcon style={{ color: "#ddd" }} />
                    </ListItemIcon>
                    <ListItemText primary="ROC Detail" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      <SchoolOutlinedIcon style={{ color: "#ddd" }} />
                    </ListItemIcon>
                    <ListItemText primary="UGC Detail" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <ListItemText primary="LOGOUT" />
                    <ListItemIcon>
                      <LogoutIcon style={{ color: "#ddd" }} />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              </List>
            </div>
            {/* {drawer} */}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                background: "linear-gradient(#3B2449, #56356A)",
                color: "#fff",
                borderRadius: "10px",
              },
            }}
            open
          >
            <Box>
              <Box>
                <Box
                  component="img"
                  sx={{
                    height: 75,
                    width: 250,
                    border: "2px solid #fff",
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                    padding: "2px 30px",
                    marginLeft: "6px",
                    marginTop: "4px",
                  }}
                  alt="Logo"
                  src="https://infomate-trial.s3.amazonaws.com/public_assets/Infomate360+Logo.png"
                />

                <Divider />
                <List>
                  <ListItem
                    disablePadding
                    sx={{
                      background: "#fff",
                      color: "#3E264D",
                      border: "1px solid #fff",
                      borderRadius: "16px",
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <HomeOutlinedIcon
                          style={{ fontSize: "23px", marginLeft: "10px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Dashboard" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <PersonOutlineOutlinedIcon
                          style={{ color: "#fff", fontSize: "23px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Employee Management" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <PersonPinOutlinedIcon
                          style={{ color: "#fff", fontSize: "23px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Report Writing" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <PictureAsPdfOutlinedIcon
                          style={{ color: "#fff", fontSize: "23px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Generate PDF" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <BrowserUpdatedOutlinedIcon
                          style={{ color: "#fff", fontSize: "23px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Download Tracker" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <GroupsOutlinedIcon
                          style={{ color: "#fff", fontSize: "23px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Client Specific" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <CorporateFareOutlinedIcon
                          style={{ color: "#fff", fontSize: "23px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary="ROC Detail" />
                    </ListItemButton>
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemButton>
                      <ListItemIcon>
                        <SchoolOutlinedIcon
                          style={{ color: "#fff", fontSize: "23px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary="UGC Detail" />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Box>
            </Box>
            <Box
              p={2}
              mt="auto"
              sx={{ display: "inline-flex", justifyContent: "center" }}
            >
              <Typography pr={1}>LOGOUT</Typography>
              <LogoutIcon />
            </Box>
            {/* {drawer} */}
          </Drawer>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default SideBar;
