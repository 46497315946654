import axios from "axios";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useContext, useEffect } from "react";
import { Button, Grid, TextField, Typography } from "@mui/material";

import Image from "../../assets/loginbg1.png";
import { BASE_URL } from "../../services/constants";
import AuthContext from "../../contexts/AuthProvider";
import {
  removeAllLocalStorageItem,
  setLocalStorageItem,
} from "../../helpers/LocalStorage";
import NotificationAlert from "../../components/NotificationAlert";
import { setEmail, setRole, setClientCode } from "../../store/user/userSlice";

const LoginScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [error, setError] = useState();
  // const [loading, setLoading] = useState(false);
  const {
    setAuth,
    setLoggedInUserEmail,
    setLoggedInUserRole,
    setLoggedInUserClientCode,
  } = useContext(AuthContext);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const inputStyle = {
    WebkitTextFillColor: "#fff",
    transition: "background-color 5000s ease-in-out 0s",
  };

  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    // setLoading(true);
    let data;

    try {
      data = {
        email: user.email,
        password: user.password,
      };
      await axios
        .post(`${BASE_URL}auth/login`, JSON.stringify(data), {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          setAuth(true);
          setLoggedInUserRole(response.data.role);
          setLocalStorageItem("loginRole", response.data.role);
          setLocalStorageItem("loginEmail", user.email);

          setLoggedInUserEmail(user.email);
          dispatch(setEmail([user.email]));

          setLoggedInUserRole(response.data.role);
          dispatch(setRole([response.data.role]));

          if (response.data.role === "4" || response.data.role === "3") {
            setLoggedInUserClientCode(response.data.clientCode);
            setLocalStorageItem("loginClientCode", response.data.clientCode);
            dispatch(setClientCode([response.data.clientCode]));
          }
          if (response.data.role === "3") {
            navigate(state?.path || "/applicantPage");
          } else {
            navigate(state?.path || "/clientPage");
          }
          return response.status;
        });
    } catch (e) {
      setError(e.response.data.message);
      setNotify({
        isOpen: true,
        message: e.response.data.message,
        type: "warning",
      });
      console.log(error);
    }
  }

  useEffect(() => {
    axios
      .get(`${BASE_URL}auth/clearcookie`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      })
      .then((response) => {
        navigate("/");
        removeAllLocalStorageItem();
        setAuth(false);

        // return response.status;
      })
      .catch((e) => {
        dispatch(setError(["Error in Clearing Cookie!", e, false]));
      });
  }, []);

  return (
    <Box
      sx={{
        background: `url(${Image})`,
        height: "100vh",
        backgroundSize: "cover",
      }}
    >
      <Box>
        <img
          style={{ width: "200px", padding: "20px" }}
          src="https://infomate-trial.s3.amazonaws.com/public_assets/Infomate360+Logo.png"
          alt="Infomate360-Logo"
        />
      </Box>
      <NotificationAlert notify={notify} setNotify={setNotify} />
      <Box
        sx={{
          width: "360px",
          height: "450px",
          background: "rgba(0, 0, 0, 0.90)",
          color: "#fff",
          top: "15%",
          left: "60%",
          position: "absolute",
          boxSizing: "border-box",
          borderRadius: "0px 70px",
          padding: "50px 60px",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} lg={12}>
              <Typography variant="h4" align="center">
                Log In
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} lg={12} my={1}>
              <Typography variant="body1" mb={2}>
                Username
              </Typography>
              <TextField
                inputProps={{ style: inputStyle }}
                sx={{
                  width: "100%",
                  input: { color: "#fff", border: "1px solid #fff" },
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                      border: "none",
                    },
                  },
                }}
                name="email"
                size="small"
                placeholder="Enter username here"
                type="email"
                value={user.email}
                required
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12}>
              <Typography variant="body1" mb={2}>
                Password
              </Typography>
              <TextField
                inputProps={{ style: inputStyle }}
                sx={{
                  underline: {
                    "&&&:before": {
                      borderBottom: "none",
                    },
                    "&&:after": {
                      borderBottom: "none",
                    },
                  },
                  width: "100%",
                  input: { color: "#fff", border: "1px solid #fff" },
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                      border: "none",
                    },
                  },
                }}
                variant="outlined"
                name="password"
                size="small"
                placeholder="Enter password here"
                type="password"
                value={user.password}
                required
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={12} mt={2}>
              <Button
                size="medium"
                variant="contained"
                sx={{
                  width: "100%",
                  borderRadius: "20px",
                  textTransform: "none",
                }}
                type="submit"
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default LoginScreen;
