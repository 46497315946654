import { useState, useEffect, Fragment } from "react";
import { Select, Tag } from "antd";

const { Option } = Select;

const ComboField = ({
  options,
  onChange,
  selectedItemsProp,
  reset,
  setReset,
}) => {
  //NOTE : THE BELOW CODE IS USED TO BIND THE SELECTED CHECKS ON PAGE LOAD
  useEffect(() => {
    if (
      selectedItemsProp &&
      Array.isArray(selectedItemsProp) &&
      selectedItemsProp.length > 0
    ) {
      setSelectedItems(selectedItemsProp);
    }
    if (reset) {
      setSelectedItems([]);
    }
  }, [selectedItemsProp]);

  const [optionsList, setOptionsList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedChecklist, setSelectedChecklist] = useState([]);

  const handleSelect = (value) => {
    setReset(false);
    if (!selectedItems.includes(value)) {
      setSelectedItems(value);
      setSelectedChecklist(value);
      onChange(value);
    }
  };

  const handleRemove = (value) => {
    setSelectedItems(selectedItems.filter((item) => item !== value));
    setSelectedChecklist(selectedChecklist.filter((item) => item !== value));
    onChange(selectedItems.filter((item) => item !== value));
  };

  useEffect(() => {
    setOptionsList(options);
  }, [options]);

  return (
    <Fragment>
      <Select
        mode="multiple"
        showSearch
        style={{ width: "100%" }}
        placeholder="Select Checks to be performed"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        optionLabelProp="value"
        value={selectedItems}
        onChange={(e) => handleSelect(e)}
      >
        {optionsList.map((option, index) => (
          <Option key={index} value={option}>
            {option}
          </Option>
        ))}
      </Select>
      {selectedItems.length > 0 && (
        <div style={{ marginTop: 16 }}>
          <p>Selected Checks:</p>
          {selectedItems.map((item) => (
            <Tag key={item} closable onClose={() => handleRemove(item)}>
              {item}
            </Tag>
          ))}
        </div>
      )}
    </Fragment>
  );
};

export default ComboField;
