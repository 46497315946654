import React, { useState, useEffect, Fragment } from "react";

import { DatePicker } from "antd";
import {
  Box,
  CardContent,
  Grid,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";

const CompanyDetails = (props) => {
  // const defaultValue = props.formData?.CompanyDetails?.[0]?.compRegdDate
  //   ? props.formData.CompanyDetails[0].compRegdDate
  //   : null;
  const [details, setDetails] = useState(
    props.formdata?.CompanyDetails || [
      {
        compName: "",
        compAddr: "",
        compRegdAddr: "",
        compRegdDate: "",
        CompStatus: "",
        compLocality: "",
        compVolume: "",
        compArea: "",
        cinNumber: "",
        ImageURI: "",
      },
    ]
  );

  const hasValidValues = () => {
    for (const compEntry of details) {
      if (
        compEntry.compName === "" ||
        compEntry.compAddr === "" ||
        compEntry.compRegdAddr === "" ||
        compEntry.compRegdDate === "" ||
        compEntry.CompStatus === "" ||
        compEntry.compLocality === "" ||
        compEntry.compVolume === "" ||
        compEntry.compArea === "" ||
        compEntry.cinNumber === ""
      ) {
        return false;
      }
      // if (!compEntry.ImageURI) {
      //   return false;
      // }
    }
    return true;
  };

  const handleInputChange = (index, field, value) => {
    setDetails((prevState) => {
      const newState = [...prevState];
      newState[index][field] = value;
      return newState;
    });
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    props.nextStep();
  };

  const handlePrevClick = (e) => {
    e.preventDefault();
    props.prevStep();
  };

  useEffect(() => {
    const page = "CompanyDetails";
    const value = [...details];
    console.log("handleSubmit event CompanyDetails: ", page, value);
    props.onChangeformvalue(page, value);
  }, [details]);

  return (
    <Fragment>
      <CardContent>
        {/* Grid 1: Header Row */}
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              style={{ fontSize: "x-large", fontWeight: "bold" }}
            >
              Company Details
            </Typography>
          </Grid>

          {/* <Grid item xs={12} md={5}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                // onClick={handleAddEntry}
              >
                Upload Document
              </Button>
            </Box>
          </Grid> */}
        </Grid>

        {/* Grid 2: Form Field */}
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Company Name"
              variant="outlined"
              fullWidth
              value={props.formdata.CompanyDetails?.[0]?.compName}
              onChange={(e) => handleInputChange(0, "compName", e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="CIN Number"
              variant="outlined"
              fullWidth
              value={props.formdata.CompanyDetails?.[0]?.cinNumber}
              onChange={(e) =>
                handleInputChange(0, "cinNumber", e.target.value)
              }
              required
            />
          </Grid>

          <Grid item xs={24} md={12}>
            <TextField
              label="Company Address"
              variant="outlined"
              fullWidth
              value={props.formdata.CompanyDetails?.[0]?.compAddr}
              onChange={(e) => handleInputChange(0, "compAddr", e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={24} md={12}>
            <TextField
              label="Company Registered Address"
              variant="outlined"
              fullWidth
              value={props.formdata.CompanyDetails?.[0]?.compRegdAddr}
              onChange={(e) =>
                handleInputChange(0, "compRegdAddr", e.target.value)
              }
              required
            />
          </Grid>

          <Grid item xs={8} md={2}>
            <Typography
              style={{
                color: "black",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Company Registration Date
            </Typography>
          </Grid>
          <Grid item xs={8} md={2}>
            <DatePicker
              popupStyle={{ width: "300px", height: "150px" }}
              value={
                props.formdata.CompanyDetails?.[0]?.compRegdDate
                  ? dayjs(
                      props.formdata.CompanyDetails?.[0]?.compRegdDate,
                      "DD/MM/YYYY"
                    )
                  : null
              }
              onChange={(date, dateString) =>
                handleInputChange(0, "compRegdDate", dateString)
              }
              format="DD/MM/YYYY"
              style={{ marginTop: "10px" }}
            />
          </Grid>
          <Grid item xs={8} md={4}>
            <TextField
              label="Company Locality"
              variant="outlined"
              fullWidth
              value={props.formdata.CompanyDetails?.[0]?.compLocality}
              onChange={(e) =>
                handleInputChange(0, "compLocality", e.target.value)
              }
              required
            />
          </Grid>
          <Grid item xs={8} md={4}>
            <TextField
              label="Company Status"
              variant="outlined"
              fullWidth
              value={props.formdata.CompanyDetails?.[0]?.CompStatus}
              onChange={(e) =>
                handleInputChange(0, "CompStatus", e.target.value)
              }
              required
            />
          </Grid>
        </Grid>

        {/* Grid 3: Navigation Button Row*/}
        <Grid container spacing={1} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Grid item xs={6} md={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handlePrevClick}
            >
              {" "}
              Prev{" "}
            </Button>
          </Grid>

          <Grid item xs={6} md={2}>
            <Button
              variant="contained"
              color="primary"
              disabled={!hasValidValues()}
              onClick={handleNextClick}
            >
              {" "}
              Next{" "}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Fragment>
  );
};

export default CompanyDetails;
