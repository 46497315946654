import React, { Component, useState } from "react";
import EmailSent from "../messages/EmailSent";
import Choose from "../caseInitiation/Choose";
import CandidateController from "../candidate/candidateController";
import BasicDetails from "../testmulitpage/BasicDetails";

const CaseInitiationForm = () => {
  const [step, setStep] = useState(1);
  const [formdata, setFormdata] = useState({});
  const [fileArraynew, setfileArraynew] = useState([]);

  // Proceed to next step
  const nextStep = () => {
    setStep(step + 1);
  };

  // Go back to prev step
  const prevStep = () => {
    setStep(step - 1);
  };

  const resetStep = () => {
    setStep(1);
    setFormdata({});
    setfileArraynew([]);
  };

  // Handle fields change
  const onChangeformvalue = (page, value) => {
    setFormdata({
      ...formdata,
      [page]: value,
    });
  };

  const onChangeformvalueforchecks = (event) => {
    if (event.target.checked) {
      setFormdata({ ...formdata, [event.target.id]: true });
    } else {
      const selectedType = {
        ...formdata,
      };
      // console.log("onChangeformvalueforchecks event", formdata);
      delete selectedType[event.target.id];

      setFormdata({ ...selectedType });
    }
  };
  // console.log("step",step);
  switch (step) {
    case 1:
      return (
        <BasicDetails
          nextStep={nextStep}
          prevStep={prevStep}
          onChangeformvalue={onChangeformvalue}
          onChangeformvalueforchecks={onChangeformvalueforchecks}
          formdata={formdata}
        />
      );
    case 2:
      return (
        <Choose
          nextStep={nextStep}
          prevStep={prevStep}
          onChangeformvalue={onChangeformvalue}
          formdata={formdata}
        />
      );
    case 3:
      if (
        formdata &&
        formdata.Choose &&
        formdata.Choose.length > 0 &&
        formdata.Choose[0].chooseOption === "Employee Uploads Documents"
      ) {
        return <EmailSent resetStep={resetStep} formdata={EmailSent} />;
      } else {
        setStep(4);
        break;
      }
    case 4:
      return <CandidateController applicantData={formdata} />;
    default:
      setStep(1);
  }
};

export default CaseInitiationForm;
