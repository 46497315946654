import { createContext, useEffect, useState } from "react";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../helpers/LocalStorage";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(false);
  const [loggedInUserRole, setLoggedInUserRole] = useState("");
  const [loggedInUserEmail, setLoggedInUserEmail] = useState("");
  const [loggedInUserClientCode, setLoggedInUserClientCode] = useState("");

  // Load the login email from localStorage on component mount
  // useEffect(() => {
  //   console.log("Executing on Logout");
  //   const storedRole = getLocalStorageItem("loginRole");
  //   const storedEmail = getLocalStorageItem("loginEmail");
  //   const storedClientCode = getLocalStorageItem("loginClientCode");

  //   console.log("storedRole", storedRole);
  //   console.log("storedEmail", storedEmail);
  //   console.log("storedClientCode", storedClientCode);

  //   if (storedRole) {
  //     setLoggedInUserRole(storedRole);
  //   }
  //   if (storedEmail) {
  //     setLoggedInUserEmail(storedEmail);
  //   }
  //   if (storedClientCode) {
  //     setLoggedInUserClientCode(storedClientCode);
  //   }
  // }, []);

  // Save the login email to localStorage when it changes
  useEffect(() => {
    // console.log("loggedInUserEmail", loggedInUserEmail);
    // console.log("loggedInUserRole", loggedInUserRole);
    // console.log("loggedInUserClientCode", loggedInUserClientCode);
    // setLocalStorageItem("loginRole", loggedInUserRole);
    // setLocalStorageItem("loginEmail", loggedInUserEmail);
    // setLocalStorageItem("loginClientCode", loggedInUserClientCode);
  }, [loggedInUserEmail, loggedInUserRole, loggedInUserClientCode]);

  return (
    <AuthContext.Provider
      value={{
        auth,
        setAuth,
        loggedInUserEmail,
        setLoggedInUserEmail,
        loggedInUserRole,
        setLoggedInUserRole,
        loggedInUserClientCode,
        setLoggedInUserClientCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
