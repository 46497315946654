import { TableRow } from "./Library";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import { ThemeProvider } from "@mui/material/styles";
import { tableTheme, StyledTableCell, StyledTableRow } from "../themes/theme";

const CustomTable = ({ headContent, bodyContent }) => {
  return (
    <ThemeProvider theme={tableTheme}>
      <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
        <Table
          sx={{ minWidth: 200 }}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead>
            <TableRow>{headContent}</TableRow>
          </TableHead>
          <TableBody>{bodyContent}</TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

export { StyledTableCell, StyledTableRow, CustomTable };
