import { configureStore } from "@reduxjs/toolkit";
import errorReducer from "./error/errorSlice";
import userReducer from "./user/userSlice";

export const store = configureStore({
  reducer: {
    error: errorReducer,
    user: userReducer,
  },
});
