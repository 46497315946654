import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  message: "",
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError: (state, action) => {
      console.log(action.payload);
      const [name, errorObj, isString] = action.payload;
      if (!isString) {
        if (errorObj.response === undefined) {
          state.message = errorObj.toString();
        } else if (errorObj.response.data === undefined) {
          state.message = errorObj.response.toString();
        } else if (errorObj.response.data.message !== undefined) {
          state.message = errorObj.response.data.message;
        }
      } else {
        state.message = errorObj;
      }
      state.name = name;
    },
  },
});

export const { setError } = errorSlice.actions;

export default errorSlice.reducer;
