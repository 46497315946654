import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

const Ribbon = (props) => {
    
    const { title } = props;

    return (
        <>
            <Box sx={{ width: "250px", height: "70px", backgroundColor: "#3B2449", display: "flex", justifyContent: "center", alignItems: "center", float: "left" }}>
                <Typography color="white" fontFamily="poppins" fontSize={18}>
                    {title}
                </Typography>
            </Box>
            <Box sx={{ borderTop: "35px solid transparent", borderLeft: "70px solid #3B2449", borderBottom: "35px solid transparent", float: "left" }} />
        </>
    )
}

export default Ribbon;