import { getRequests, postRequests } from "./commonServices";
import { BASE_URL } from "./constants";

export const registerApplicant = async (onSuccess, dispatch, payload) => {
  return await postRequests(
    onSuccess,
    dispatch,
    payload,
    `${BASE_URL}auth/register`
  );
};

export const addApplicantData = async (onSuccess, dispatch, payload) => {
  return await postRequests(
    onSuccess,
    dispatch,
    payload,
    `${BASE_URL}tempApplicant/saveOrUpdate`
  );
};

export const getApplicantData = async (onSuccess, dispatch, userEmail) => {
  return await getRequests(
    onSuccess,
    dispatch,
    `${BASE_URL}tempApplicant/${userEmail}`
  );
};

export const getApplicantList = async (onSuccess, dispatch, clientCode) => {
  return await getRequests(
    onSuccess,
    dispatch,
    `${BASE_URL}tempApplicant/clientCode/${clientCode}`
  );
};

export const getAllApplicantList = async (onSuccess, dispatch) => {
  return await getRequests(
    onSuccess,
    dispatch,
    `${BASE_URL}tempApplicant/allApplicants`
  );
};
