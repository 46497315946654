import React, { Fragment, useEffect, useState } from "react";
import Ribbon from "../../components/Ribbon";
import CardStyle from "../../components/CardStyle";
import { ThemeProvider } from "@mui/material/styles";
import ClientSideBar from "../../components/ClientSideBar";
import { colorTheme, generalTheme } from "../../themes/theme";
import {
  Box,
  CardContent,
  Grid,
  Button,
  Input,
  TextField,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const ReferenceDetails = (props) => {
  const [details, setDetails] = useState(
    props.formdata?.ReferenceDetails || [
      {
        refName: "",
        refDesignation: "",
        refMobile: "",
        refEmail: "",
        refAssociation: "",
        ImageURI: "",
      },
    ]
  );
  const [indext, setindext] = useState(0);
  const hiddenFileInput = React.useRef(null);

  const handleUploadImageClick = (event) => {
    const id = parseInt(event.target.id);
    if (isNaN(id)) {
      setindext(0);
    } else {
      setindext(id);
    }
    // console.log("handleUploadImageClick", event, typeof id);
    hiddenFileInput.current.click();
  };

  const handleChangeForImageInput = (e) => {
    const fileUploaded = e.target.files[0];
    console.log("GAp fileUploaded", fileUploaded);
    const { name } = e.target;
    const v = URL.createObjectURL(fileUploaded);

    console.log("name", name, "V", v, "indext", indext);
    setDetails((prevState) => {
      const updatedDetails = [...prevState];
      updatedDetails[indext][name] = v;
      return updatedDetails;
    });
  };

  const removeImage = (id) => {
    // this is the line that you are looking for
    console.log("id, hiddenFileInput.current.value", id, details, "index");
    details.map((data, index) =>
      data.ImageURI === id
        ? setDetails((prevState) => {
            const updatedDetails = [...prevState];
            updatedDetails[index]["ImageURI"] = "";
            return updatedDetails;
          })
        : null
    );
    // setDetails((details) =>
    //   details.filter((tag, i) => console.log("tag.ImageURI, id", tag, id))
    // );
    hiddenFileInput.current.value = null;

    // setDetails((prevState) => {
    //   const updatedDetails = [...prevState];
    //   updatedDetails[indext][name] = v;
    //   return updatedDetails;
    // });
    // props.setfileArraynew((fileArraynew1) =>
    //   fileArraynew1.filter((item) => item !== id)
    // );
  };

  const handleInputChange = (index, field, value) => {
    setDetails((prevState) => {
      const newState = [...prevState];
      newState[index][field] = value;
      return newState;
    });
  };

  const handleAddEntry = () => {
    setDetails((prevState) => [
      ...prevState,
      {
        refName: "",
        refDesignation: "",
        refMobile: "",
        refEmail: "",
        refAssociation: "",
        ImageURI: "",
      },
    ]);
  };

  const handleDeleteEntry = (index) => {
    if (details.length === 2) {
      return; // Do not allow deleting the last entry
    }
    setDetails((details) => details.filter((_, i) => i !== index));
    // const newGapEntries = [...details];
    // newGapEntries.splice(index, 1);
    // setDetails(newGapEntries);
  };

  const hasValidValues = () => {
    for (const refEntry of details) {
      if (
        refEntry.refName === "" ||
        refEntry.refDesignation === "" ||
        refEntry.refMobile === "" ||
        refEntry.refEmail === "" ||
        details.length < 2
      ) {
        console.log("details: ", details);
        return false;
      }
      // Check if a document is uploaded for this entry
      //   if (!refEntry.document) {
      //     return false;
      //   }
    }
    return true;
  };

  const handlePrevClick = (e) => {
    e.preventDefault();
    props.prevStep();
  };

  const handleRefSaveClick = () => {
    console.log("Entries: ", details);
    console.log("Values: ", hasValidValues());
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    props.nextStep();
  };

  useEffect(() => {
    const page = "ReferenceDetails";
    const value = [...details];
    console.log("handleSubmit eveent ReferenceDetails", page, value);
    props.onChangeformvalue(page, value);
  }, [details]);

  return (
    <Fragment>
      <CardContent>
        {/* Grid 1 */}
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              style={{ fontSize: "x-large", fontWeight: "bold" }}
            >
              Reference Details
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddEntry}
              >
                Add Entry
              </Button>
            </Box>
          </Grid>
        </Grid>

        {/* Grid 2 */}
        <div style={{ height: "360px", overflow: "auto" }}>
          {details.map((refEntry, index) => (
            <>
              <Grid container spacing={1} key={index}>
                {index > 0 ? (
                  <Grid item xs={24} md={11} sx={{ mt: 4 }}>
                    <TextField
                      label="Name of the Reference"
                      variant="outlined"
                      fullWidth
                      value={props.formdata.ReferenceDetails?.[index]?.refName}
                      onChange={(e) =>
                        handleInputChange(index, "refName", e.target.value)
                      }
                      required
                    />
                  </Grid>
                ) : (
                  <Grid item xs={24} md={11} sx={{ mt: 2 }}>
                    <TextField
                      label="Name of the Reference"
                      variant="outlined"
                      fullWidth
                      value={props.formdata.ReferenceDetails?.[index]?.refName}
                      onChange={(e) =>
                        handleInputChange(index, "refName", e.target.value)
                      }
                      required
                    />
                  </Grid>
                )}
                <Grid item xs={24} md={11}>
                  <TextField
                    label="Designation of the Reference"
                    variant="outlined"
                    fullWidth
                    value={
                      props.formdata.ReferenceDetails?.[index]?.refDesignation
                    }
                    onChange={(e) =>
                      handleInputChange(index, "refDesignation", e.target.value)
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    label="Mobile No"
                    variant="outlined"
                    fullWidth
                    value={props.formdata.ReferenceDetails?.[index]?.refMobile}
                    onChange={(e) =>
                      handleInputChange(index, "refMobile", e.target.value)
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={props.formdata.ReferenceDetails?.[index]?.refEmail}
                    onChange={(e) =>
                      handleInputChange(index, "refEmail", e.target.value)
                    }
                    required
                  />
                </Grid>
                <Grid item xs={24} md={11}>
                  <TextField
                    label="Name of Organization"
                    variant="outlined"
                    fullWidth
                    value={
                      props.formdata.ReferenceDetails?.[index]?.refAssociation
                    }
                    onChange={(e) =>
                      handleInputChange(index, "refAssociation", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={24} md={11}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDeleteEntry(index)}
                      style={{ marginLeft: "auto" }}
                    >
                      Delete
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </>
          ))}
        </div>

        {/* Grid 3 */}
        <Grid container spacing={1} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Grid item xs={14} md={8}>
            <Typography style={{ color: "red", fontWeight: "bold" }}>
              *Atleast 2 professional references are mandatory. Click on Add
              Entry to add another form.
            </Typography>
          </Grid>
          <Grid item xs={5} md={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handlePrevClick}
            >
              {" "}
              Prev{" "}
            </Button>
          </Grid>
          {/* <Grid item>
                        <Button variant="contained" color="secondary" disabled='true'> Cancel </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleRefSaveClick}> Save </Button>
                    </Grid> */}
          <Grid item xs={5} md={1.8}>
            <Button
              variant="contained"
              color="primary"
              disabled={!hasValidValues()}
              onClick={handleNextClick}
            >
              {" "}
              Next{" "}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Fragment>
  );
};
export default ReferenceDetails;
