import { useDispatch } from "react-redux";
import React, { Fragment, useEffect, useState, useContext } from "react";

import { Radio, Button, Modal } from "antd";
import { Box, CardContent, CircularProgress, Typography } from "@mui/material";

import { sendMail } from "../../services/emailServices";
import { AuthContext } from "../../contexts/AuthProvider";
import {
  registerApplicant,
  addApplicantData,
} from "../../services/applicantServices";
import { getLocalStorageItem } from "../../helpers/LocalStorage";

const Choose = (props) => {
  let img = [];
  let clientCompany = props.formdata.BasicDetail[0].applicantCompanyName;
  let applicantClientCode = getLocalStorageItem("loginClientCode");
  const dispatch = useDispatch();
  const { loggedInUserEmail } = useContext(AuthContext);

  const [selectedOption, setSelectedOption] = useState(null);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [entries, setEntries] = useState(
    props.formdata?.Choose || [
      {
        chooseOption: "",
      },
    ]
  );

  const sendCandidateEmail = async (candidatePayload, cxPayload, resolve) => {
    await sendMail(handleCandidateEmailSuccess, dispatch, candidatePayload);
    sendInfomateEmail(cxPayload, resolve);
  };

  const sendInfomateEmail = async (clientPayload, resolve) => {
    await sendMail(handleInfomateEmailSuccess, dispatch, clientPayload);
    resolve();
  };

  const handleCandidateEmailSuccess = (res) => {
    if (res && res.data && res.status === 200) {
      console.log("Candidate: Info saved | Email Triggered");
    }
  };
  const handleInfomateEmailSuccess = (res) => {
    if (res && res.data && res.status === 200) {
      console.log("Infomate Team: New Case | Informed");
    }
  };

  const handleEmail = (password) => {
    return new Promise((resolve, reject) => {
      for (const [key, value] of Object.entries(props.formdata)) {
        value.map((item, i) =>
          item?.ImageURI
            ? img.push(
                new File([item?.ImageURI], `${key}_${i + 1}.jpg`, {
                  type: item?.ImageURI.type,
                })
              )
            : null
        );
      }
      let { candidateTemplate, infomateTemplate } = "";
      if (
        props.formdata.BasicDetail &&
        Array.isArray(props.formdata.BasicDetail) &&
        props.formdata.BasicDetail.length > 0
      ) {
        let { applicantEmail, applicantName } = props.formdata.BasicDetail[0];
        candidateTemplate = `<div className="Candidate">
            <h4>Dear ${applicantName},</h4>
            <div>This is regarding your background verification initiated by your employer: <a><b>${clientCompany}</b></a>.</div>
            <div>Kindly click on the following link <a href="portal.infomate360.com" target="_blank">portal.infomate360.com </a>, and login using the below credentials.</div>
            <p></p>
            <a>Login Id : ${applicantEmail
              .toString()
              .replace("@", "<span>@</span>")
              .replace(".", "<span>.</span>")}</a>
            <p>Password : ${password}</p>
            <br />
            <div>Kindly, fill the mentioned details and upload relevant documents to help us complete the procedure.</div>
            <br />
            <br />
            <div>Thanks and Regards,</div>
            <div>Team Infomate360</div>
            </div>`;

        infomateTemplate = `<div className="Infomate">
          <h4>
            Dear Team,
          </h4>
          <p>
            ${clientCompany} has initiated background verification case for below candidate:&nbsp;
            <br><strong>Name:&nbsp;</strong>${applicantName}
            <br><strong>Email:&nbsp;</strong>${applicantEmail}
            <br>
            <br>The above candidate will be uploading the required documents on the portal as per the checks selected by the employer.
          </p>
          <p>
            You&apos;ll get another email once candidate uploads the documents and submit the complete information successfully on the portal. Post that you can manage the status of this case by visiting the &quot;Case Status&quot; section of our portal (<a href="http://portal.infomate360.com">Infomate Portal</a>).
          </p>
          <p>
            <br>--
            <br>From,&nbsp;
            <br>Automated Mailer System
          </p>
        </div>`;

        const candidateData = {
          receiver: props.formdata.BasicDetail?.[0].applicantEmail,
          subject: `Infomate360: Background Verification Initiated | Upload Documents on Portal`,
          body: candidateTemplate,
          cc: "",
          bcc: "",
        };
        const infomateData = {
          receiver:
            "ops@infomate360.com,latha@infomate360.com,preethi@infomate360.com",
          subject: `Candidate Side: Background Verification Initiated | ${applicantName} | ${clientCompany}`,
          body: infomateTemplate,
          cc: "sai@infomate360.com",
          bcc: "",
        };

        var candidateBodyFormData = new FormData();
        candidateBodyFormData.append("data", JSON.stringify(candidateData));
        var infomateBodyFormData = new FormData();
        infomateBodyFormData.append("data", JSON.stringify(infomateData));

        console.log("Candidate Body: ", candidateBodyFormData);
        console.log("Infomate Body: ", infomateBodyFormData);

        sendCandidateEmail(
          candidateBodyFormData,
          infomateBodyFormData,
          resolve
        );
      } else {
        alert("Missing Information: Can't proceed. Contact Infomate Team.");
      }
    });
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    props.prevStep();
  };

  const handleConfirmClick = () => {
    // create a copy of entries
    const updatedEntries = [...entries];

    // find the entry object at index 0 and update its chooseOption property
    updatedEntries[0] = {
      ...updatedEntries[0],
      chooseOption:
        selectedOption === 1
          ? getLocalStorageItem("loginRole") === "2" ||
            getLocalStorageItem("loginRole") === "1"
            ? "Infomate Uploads Documents"
            : "Employer Uploads Documents"
          : "Employee Uploads Documents",
    };

    // update entries state
    setEntries(updatedEntries);

    // open confirmation modal
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = async (e) => {
    setIsModalVisible(false);
    if (selectedOption === 2) {
      setIsSendingEmail(true);
    }
    if (
      props.formdata.BasicDetail &&
      Array.isArray(props.formdata.BasicDetail) &&
      props.formdata.BasicDetail.length > 0
    ) {
      let {
        applicantEmpId,
        applicantEmail,
        applicantName,
        applicantMobile,
        applicantChecks,
        applicantDOJ,
      } = props.formdata.BasicDetail[0];

      let { applicantEntryPayload } = {};
      applicantEntryPayload = {
        empId: applicantEmpId,
        email: applicantEmail,
        applicantName: applicantName,
        clientCode: applicantClientCode,
        applicantJoining: applicantDOJ,
        packages: applicantChecks,
        createdBy: getLocalStorageItem("loginEmail"),
      };

      await addApplicantData(
        handleOnSuccessData,
        dispatch,
        applicantEntryPayload
      );

      if (
        (props.formdata?.chooseOption?.[0]?.chooseOption &&
          props.formdata?.chooseOption?.[0]?.chooseOption ===
            "Employee Uploads Documents") ||
        entries[0].chooseOption === "Employee Uploads Documents"
      ) {
        let { applicantCredentialsPayload } = {};

        applicantCredentialsPayload = {
          email: applicantEmail,
          firstName: applicantName,
          lastName: "",
          contact: applicantMobile,
          status: "ACTIVE",
          role: 3,
          clientCode: applicantClientCode,
        };

        await registerApplicant(
          handleOnSuccess,
          dispatch,
          applicantCredentialsPayload
        );

        e.preventDefault();
      } else {
        props.nextStep();
        e.preventDefault();
      }
    }
  };

  const handleOnSuccessData = (res) => {
    if (res && res.data && res.data.success) {
    }
  };

  const handleOnSuccess = async (res) => {
    if (res && res.data && res.data.success && res.data.password) {
      await handleEmail(res.data.password);
      setIsSendingEmail(false);
      props.nextStep();
    }
  };

  const options = [
    {
      label:
        getLocalStorageItem("loginRole") === "2" ||
        getLocalStorageItem("loginRole") === "1"
          ? "Infomate Uploads Documents"
          : "Employer Uploads Documents",
      value: 1,
    },
    { label: "Employee Uploads Documents", value: 2 },
  ];

  useEffect(() => {
    const page = "Choose";
    const value = [...entries];
    props.onChangeformvalue(page, value);
  }, [entries]);

  return (
    <Fragment>
      {!isSendingEmail ? (
        <>
          <Radio.Group
            style={{ alignContent: "center" }}
            onChange={(e) => {
              setSelectedOption(e.target.value);
            }}
          >
            {options.map((option) => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Radio.Group>

          <div style={{ marginTop: 16 }}>
            <Button onClick={handleBackClick}>Back</Button>
            <Button
              type="primary"
              onClick={handleConfirmClick}
              disabled={!selectedOption}
            >
              Confirm
            </Button>
          </div>

          <Modal
            title="Confirmation"
            open={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <p>
              Are you sure you want to{" "}
              {selectedOption === 1 ? (
                <strong>upload document yourself</strong>
              ) : (
                <strong>let candidate upload documents</strong>
              )}
              ?
            </p>
          </Modal>
        </>
      ) : (
        <CardContent>
          <Box sx={{ textAlign: "center", my: 19 }}>
            <CircularProgress sx={{ mb: 1 }} />
            <Typography variant="h4" sx={{ fontWeight: 500 }}>
              {" "}
              Email is being Sent
            </Typography>
          </Box>
        </CardContent>
      )}
    </Fragment>
  );
};

export default Choose;
