import { useDispatch } from "react-redux";
import React, { Fragment, useEffect, useState } from "react";

import { Button as Btn } from "antd";
import { pdfConverter } from "../../utilityComponents/pdfConverter";
import { Box, CircularProgress, Typography } from "@mui/material";
import { CardContent, Grid, Checkbox, Button } from "@mui/material";

import { sendMail } from "../../services/emailServices";
import { DeclarationTemplateFun } from "./DeclarationTemplateFun";
import SignatureComponent from "../../utilityComponents/Signature";
import { deleteUserCredentials } from "../../services/userServices";
import { getClientSpecificByCode } from "../../services/clientServices";
import { getLocalStorageItem } from "../../helpers/LocalStorage";

const Declaration = (props) => {
  const dispatch = useDispatch();
  let img = [];

  const [pdfBlob, setPdfBlob] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [visible, setVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [clientCompany, setClientCompany] = useState("");
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [details, setDetails] = useState(
    props.formdata?.Declaration || [
      {
        signature: "signId",
        ImageURI: "",
      },
    ]
  );

  const handleEmail = () => {
    return new Promise((resolve, reject) => {
      for (const [key, value] of Object.entries(props.formdata)) {
        value.map((item, i) => {
          item?.ImageURI &&
            item.ImageURI.length > 0 &&
            item.ImageURI.map((file, index) =>
              img.push(
                new File([file], `${key}_${i + 1}_${file.name}`, {
                  type: file.type,
                })
              )
            );
        });
      }
      let { clientEmail, candidateName, candidateEmail } = "";
      candidateName =
        props.formdata.PersonalDetails[0].firstName +
        " " +
        props.formdata.PersonalDetails[0].lastName;
      if (getLocalStorageItem("loginRole") === "3") {
        candidateEmail = getLocalStorageItem("loginEmail");
      } else {
        console.log("Props: ", props);
        candidateEmail = props.applicantData.BasicDetail[0].applicantEmail;
      }
      clientEmail = getLocalStorageItem("createdBy");

      const sendCandidateEmail = async (candidatePayload) => {
        await sendMail(handleCandidateEmailSuccess, dispatch, candidatePayload);
      };
      const deleteCandidateCall = async (userEmail) => {
        await deleteUserCredentials(dispatch, userEmail);
      };
      const sendClientEmail = async (clientPayload) => {
        await sendMail(handleClientEmailSuccess, dispatch, clientPayload);
        resolve();
      };

      const handleCandidateEmailSuccess = (res) => {
        if (res && res.data && res.status === 200) {
          let resp = deleteCandidateCall(candidateEmail);
          console.log("Response Delete: ", resp);
          sendClientEmail(clientFormData);
        }
      };
      const handleClientEmailSuccess = (res) => {
        if (res && res.data && res.status === 200) {
          console.log("Database Updated.");
        }
      };

      const candidateBody = `${Object.entries(props.formdata)
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            // If the value is an array, create a table for each array element
            return value
              .map((item, index) => {
                return `
                <h2>${key} ${index + 1}</h2>
                <table style="font-family: arial, sans-serif; border-collapse: collapse; width: 100%; border: 1px solid #dddddd;">
                  <tr>
                    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Field</th>
                    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Value</th>
                  </tr>
                  ${Object.entries(item)
                    .filter(([key1, value2]) => key1 !== "ImageURI")
                    .map(
                      ([key1, value2]) => `
                      <tr>
                        <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${key1}</td>
                        <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${value2}</td>
                      </tr>
                    `
                    )
                    .join("")}
                </table>
              `;
              })
              .join("");
          } else {
            // If the value is not an array, create a single table for the key-value pair
            return `
            <h2>${key}</h2>
            <table style="font-family: arial, sans-serif; border-collapse: collapse; width: 100%; border: 1px solid #dddddd;">
              <tr>
                <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Field</th>
                <th style="border: 1px solid #dddddd; text-align: left; padding: 8px;">Value</th>
              </tr>
              ${Object.entries(value)
                .filter(([key1, value2]) => key1 !== "ImageURI")
                .map(
                  ([key1, value2]) => `
                  <tr>
                    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${key1}</td>
                    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${value2}</td>
                  </tr>
                `
                )
                .join("")}
            </table>
          `;
          }
        })
        .join("")}
    `;
      const clientBody = `<div className="Client">
            <h4>Hello,</h4>
            <p>This is regarding the background verification initiated by you for below applicant:&nbsp;
            <br><strong>Name:&nbsp;</strong>${candidateName}
              <br><strong>Email:&nbsp;</strong>${candidateEmail}
              <br>
              <br>The above candidate has uploaded the required documents as per the checks selected by yourself on the portal.
              <br>
                <br>You can check the status of this case by visiting the &quot;Case Status&quot; section of our portal (<a href="http://portal.infomate360.com">Infomate Portal</a>).</p>
            <p>
              <br>--
              <br>Thanks and Regards,
              <br>Team Infomate360 </p> 
            </div>`;

      const candidateData = {
        receiver:
          "ops@infomate360.com,latha@infomate360.com,preethi@infomate360.com",
        subject: `BGV: New Case Initiated | ${candidateName} | ${clientCompany}`,
        body: candidateBody,
        cc: "sai@infomate360.com",
        bcc: "",
      };

      const clientData = {
        receiver: `${clientEmail}`,
        subject: `BGV: Document Uploaded | ${candidateName} | Infomate360`,
        body: clientBody,
        cc: "",
        bcc: "",
      };

      var candidateFormData = new FormData();
      console.log("Candidate Body: ", candidateBody);
      candidateFormData.append("data", JSON.stringify(candidateData));
      console.log("PDF Blob: ", pdfBlob);
      candidateFormData.append("file", pdfBlob, "Candidate-Declaration.pdf");
      img.map((item, index) => candidateFormData.append(`file${index}`, item));

      var clientFormData = new FormData();
      console.log("Client Body: ", clientBody);
      clientFormData.append("data", JSON.stringify(clientData));

      sendCandidateEmail(candidateFormData);
    });
  };

  const hasValidValues = () => {
    // Check if a document is uploaded for this entry
    if (!imageUrl) {
      return false;
    }
    return true;
  };

  const handleOk = () => {
    setVisible(false);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const handlePrevClick = (e) => {
    e.preventDefault();
    if (
      props &&
      props.formdata &&
      props.formdata.AdditionalDetails &&
      Array.isArray(props.formdata.AdditionalDetails) &&
      props.formdata.AdditionalDetails.length < 1
    ) {
      props.specPrevStep();
    } else {
      props.prevStep();
    }
  };

  const handleSubmitClick = async (e) => {
    e.preventDefault();
    try {
      await handleEmail();
      props.nextStep();
    } catch (error) {
      console.error(error);
    }
    setIsSendingEmail(false);
  };

  const handleClientCodeSpecificSuccess = (res) => {
    setClientCompany(res.data.data.ClientName);
  };

  useEffect(() => {
    const page = "Declaration";
    const value = [...details];
    props.onChangeformvalue(page, value);
  }, [details]);

  useEffect(() => {
    async function fetchData() {
      const applicantName =
        props.formdata.PersonalDetails[0].firstName +
        " " +
        props.formdata.PersonalDetails[0].lastName;
      if (imageUrl) {
        let sDeclarationTemplate = DeclarationTemplateFun({
          sClientCompanyName: clientCompany,
          sName: applicantName,
          imageUrl: imageUrl,
        });

        let response = await pdfConverter(sDeclarationTemplate);
        setPdfBlob(response);
      }
    }
    fetchData();
  }, [imageUrl]);

  useEffect(() => {
    getClientSpecificByCode(
      handleClientCodeSpecificSuccess,
      dispatch,
      getLocalStorageItem("loginClientCode")
    );
  }, []);

  return (
    <Fragment>
      {!isSendingEmail ? (
        <CardContent>
          {/* Grid 1 */}
          <Grid container>
            <Grid item xs={24} md={12}>
              <Typography
                variant="h6"
                style={{ fontSize: "x-large", fontWeight: "bold" }}
              >
                Background Verification Consent and Declaration
              </Typography>
            </Grid>
          </Grid>

          {/* Grid 2 */}
          <Grid container style={{ marginTop: "1%" }}>
            <Grid item xs={1} md={0.5}>
              <Typography
                variant="p"
                style={{
                  fontSize: "small",
                  fontWeight: "bold",
                  align: "center",
                }}
              >
                {" "}
                1.
              </Typography>
            </Grid>
            <Grid item xs={23} md={11.5}>
              <Typography variant="p" style={{ fontSize: "small" }}>
                The information furnished is factually correct and subject to
                verification by Infomate.
              </Typography>
            </Grid>

            <Grid item xs={1} md={0.5}>
              <Typography
                variant="p"
                style={{ fontSize: "small", fontWeight: "bold" }}
              >
                {" "}
                2.
              </Typography>
            </Grid>
            <Grid item xs={23} md={11.5}>
              <Typography variant="p" style={{ fontSize: "small" }}>
                All my education qualifications have been acquired from
                institutes duly recognized by Govt. established statutory
                authorities.
              </Typography>
            </Grid>

            <Grid item xs={1} md={0.5}>
              <Typography
                variant="p"
                style={{ fontSize: "small", fontWeight: "bold" }}
              >
                {" "}
                3.
              </Typography>
            </Grid>
            <Grid item xs={23} md={11.5}>
              <Typography variant="p" style={{ fontSize: "small" }}>
                I accept that an appointment given to me can be revoked; if any
                of above information has been misstated or unstated
              </Typography>
            </Grid>
          </Grid>

          {/* Grid 3 */}
          <Grid
            container
            style={{ height: 70, overflow: "auto", paddingTop: 20 }}
          >
            <Grid item xs={1} md={0.5}>
              <Checkbox
                sx={{ padding: 0, "& .MuiSvgIcon-root": { fontSize: 28 } }}
                disabled={!hasValidValues()}
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
            </Grid>
            <Grid item xs={23} md={11.5}>
              <Typography variant="p" style={{ fontSize: "small" }}>
                Agree to the terms and Conditions.
              </Typography>
            </Grid>
          </Grid>

          {/* Grid 4 */}
          <Grid
            container
            style={{ height: 170, overflow: "auto", paddingTop: 20 }}
          >
            <Grid item xs={24} md={12}>
              <Btn type="primary" onClick={() => setVisible(true)}>
                Signature
              </Btn>
              <SignatureComponent
                visible={visible}
                onCancel={handleCancel}
                onSave={handleOk}
                setImageUrl={setImageUrl}
                imageUrl={imageUrl}
              />
              {console.log("Dummy: ", imageUrl)}
              {/* <ImagePicker setImageUrl={setImageUrl} imageUrl={imageUrl} /> */}
            </Grid>
          </Grid>

          {/* Grid 5 */}
          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item xs={6} md={5}>
              <Typography style={{ color: "red", fontWeight: "bold" }}>
                *You need to Upload Signature before accepting TnC's. Upload
                only JPEG/JPG/PNG file for signature.
              </Typography>
            </Grid>

            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={handlePrevClick}
              >
                {" "}
                Prev{" "}
              </Button>
            </Grid>
            <Grid item style={{ paddingRight: 25 }}>
              <Button
                variant="contained"
                color="primary"
                disabled={!isChecked}
                onClick={(e) => {
                  setIsSendingEmail(true);
                  handleSubmitClick(e);
                }}
              >
                {" "}
                Submit{" "}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      ) : (
        <CardContent>
          <Box sx={{ textAlign: "center", my: 19 }}>
            <CircularProgress sx={{ mb: 1 }} />
            <Typography variant="h4" sx={{ fontWeight: 500 }}>
              {" "}
              Saving Record in Database. Please Wait.....
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 500, color: "red" }}>
              {" "}
              Don't refresh the page or click Back button.
            </Typography>
          </Box>
        </CardContent>
      )}
    </Fragment>
  );
};

export default Declaration;
