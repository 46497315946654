import React, { useEffect, useState } from "react";
import { Label } from "reactstrap";
import { Form, Modal, Upload, message } from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  EyeOutlined,
  DeleteOutlined,
  UploadOutlined,
} from "@ant-design/icons";
const ImagePicker = (props) => {
  let { rowData, form, profileImage, imageUrl, setImageUrl } = props;
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  // const [imageUrl, setImageUrl] = useState("");
  const [sLoading, setSLoading] = useState(true);
  const [isUpdateCall, setIsUpdateCall] = useState(false);
  const customRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file) => {
    const isLt100KB = (file.size / 1024).toFixed(0) <= 10000;
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("Logo format is invalid");
      // file.originFileObj = {};
      // this is when the wrong format is uploaded but I am nt sure if it is correct
    }
    if (!isLt100KB && isJpgOrPng) {
      message.error("Logo size cannot be more than 100 KB");
    }
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
    if (isJpgOrPng && isLt100KB) {
      setIsUpdateCall(true);
    } else if ((!isJpgOrPng && isLt100KB) || !isJpgOrPng || !isLt100KB) {
      setIsUpdateCall(false);
    }
    return isJpgOrPng && isLt100KB;
  };

  const handleUploadImage = (info) => {
    if (isUpdateCall) {
      if (info.file.status === "uploading") {
        setSLoading(false);
      }
      if (info.file.response === "ok" && info.file.status === "done") {
        // Get this url from response in real world.
        setSLoading(true);
        getBase64(info.file.originFileObj, (imageUrl) => {
          console.log("Info: ", imageUrl);
          setImageUrl(imageUrl);
          // form.setFieldsValue({ tBase64Image: imageUrl });
        });
      }
    } else {
      if (
        profileImage &&
        Array.isArray(profileImage) &&
        profileImage.length > 0
      ) {
        let { url } = profileImage[0];
        setImageUrl(url);
        form.setFieldsValue({ tBase64Image: url });
      }
    }
  };

  const handlePreview = async (file) => {
    setPreviewVisible(true);
  };

  const handleDeleteImg = () => {
    setImageUrl("");
  };

  const handleCancel = () => setPreviewVisible(false);

  const uploadButton = (
    // <div style={{ height: "110px", width: "100px" }}>
    //   <span> {sLoading ? <PlusOutlined /> : <LoadingOutlined />}</span>
    //   <br />
    //   <Label style={{ fontWeight: 500, marginTop: "8px" }}>
    //     {" "}
    //     Signature Pic
    //   </Label>
    //   <br />
    //   <Label style={{ fontSize: "10px" }}>
    //     <i className={"pe-7s-info"}> </i>
    //     &nbsp;Acceptable Signature pic size is upto 100 KB
    //   </Label>
    // </div>
    <>
      <p className="ant-upload-drag-icon">
        <UploadOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p className="ant-upload-hint">Support for a single upload.</p>
    </>
  );

  return (
    <>
      <div
        style={{
          height: 200,
          width: 400,
          padding: 10,
          marginLeft: 35,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Form.Item
          name="tBase64Image"
          initialValue={{ tBase64Image: "" }}
          style={{ width: 500, marginBottom: "0px" }}
        >
          <Upload.Dragger
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={handleUploadImage}
            customRequest={customRequest}
            accept="image/png,image/jpg,image/jpeg"
          >
            {imageUrl ? (
              <img
                src={imageUrl}
                alt="avatar"
                className="upload_img_isp"
                style={{ width: "50%", height: "50%", objectFit: "contain" }}
              />
            ) : (
              uploadButton
            )}
          </Upload.Dragger>
        </Form.Item>
        {/* <Modal
          visible={previewVisible}
          title={previewTitle ? previewTitle : "Profile Image"}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={imageUrl} />
        </Modal> */}

        {imageUrl ? (
          <div>
            {/* <EyeOutlined
              style={{ marginRight: "10px", cursor: "pointer" }}
              onClick={handlePreview}
            /> */}
            <DeleteOutlined
              style={{ cursor: "pointer" }}
              onClick={handleDeleteImg}
            />
          </div>
        ) : (
          ""
        )}
        {/* <strong>For Document</strong> */}
      </div>
    </>
  );
};
export default ImagePicker;
