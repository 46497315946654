import React from "react";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";

function CustomButton(props) {
  const { buttonName, startIcon, endIcon, onClick } = props;

  return (
    <Button
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      variant="contained"
      onClick={onClick}
      sx={{
        // minWidth: 50,
        maxWidth: "69px",
        padding: "0px 0px",
        backgroundColor: "#0488BD",
        borderRadius: 3,
        "&:hover": {
          backgroundColor: "#77CAEB",
        },
      }}
    >
      <Typography
        variant="button"
        // fontWeight={"bold"}
        sx={{ fontSize: "11px", fontWeight: "500" }}
      >
        {props.buttonName}
      </Typography>
    </Button>
  );
}

export default CustomButton;
