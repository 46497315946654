import { createTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { TableRow } from "../components/Library";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";

const tableTheme = createTheme({
  typography: {
    fontFamily: "Poppins",
    fontSize: 14,
  },
});
const colorTheme = createTheme({
  palette: {
    darkPurple: "#3E264D",
    lightPurple: "#DCD5E0",
    brightPurple: "#7A459C",
    statusGreenBg: "#D8FFD9",
    statusGreenBorder: "#00C808",
    statusRedBg: "#FFDADA",
    statusRedBorder: "#F80000",
    editBlue: "#007BE5",
    tableTextColor: "#212121",
    deleteRed: "#F24822",
  },
});

const generalTheme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontSize: 11,
    fontWeight: "medium",
  },
});
//Styling each table cell
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: colorTheme.palette.darkPurple, //"#3E264D", //Dark purple color for heading,
    color: theme.palette.common.white,
  },
  // [`&.${tableCellClasses.body}`]: {
  //   fontSize: 14,
  //   fontfamily: "Poppins",
  // },
}));

//Styling table row
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: colorTheme.palette.lightPurple, //Light purple color for alternate rows,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

// @Input field
const textAreaSX = {
  width: "100%",
  // marginTop: 2,
  ".MuiOutlinedInput-root": {
    // Border Color
    "& fieldset": {
      borderColor: "black",
    },
    "&:hover fieldset": {
      //Border Color on Hover
      borderColor: "blue",
    },
    "&.Mui-focused fieldset": {
      //Border Color on Focus
      borderColor: "blue",
    },
  },
};

//@AutoComplete Field
const autoCompleteSX = {
  width: "100%",

  ".MuiOutlinedInput-root": {
    //Border Color
    "& fieldset": {
      borderColor: "black",
      height: "49px",
      marginTop: 0.5,
    },
    "&:hover fieldset": {
      //Border Color on Hover
      borderColor: "blue",
    },
    "&.Mui-focused fieldset": {
      //Border Color on Focus
      borderColor: "blue",
    },
  },
};

export {
  tableTheme,
  colorTheme,
  textAreaSX,
  autoCompleteSX,
  StyledTableRow,
  StyledTableCell,
  generalTheme,
};
