import React, { Fragment, useEffect, useState, useRef } from "react";

import { Modal, Tooltip } from "antd";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, CardContent, Grid, Button, Input } from "@mui/material";
import UploadComponent from "../../utilityComponents/UploadComponent";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const EducationForm = (props) => {
  const hiddenFileInput = useRef(null);
  const [imgDocumentData, setImgDocumentData] = useState([]);

  const [details, setDetails] = useState(
    props.formdata?.EducationDetails || [
      {
        college: "",
        degree: "",
        branch: "",

        tenure: "",
        percentage: "",
        ImageURI: [],
      },
    ]
  );

  const info = (empEntry, index) => {
    Modal.info({
      title: "Upload Education Documents from below list only",
      content: (
        <div>
          <p>Highest Education Final Year Marksheet</p>
          <p>Degree Certificate / Provisional Certificate</p>
          <p>
            Course Completion Certification / Consolidated Marks Memo / Passing
            Certificate
          </p>
          <br />
        </div>
      ),
      footer: [
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ color: "red", fontWeight: "bold" }}>
            {" "}
            ** File size should be less than 4MB.{" "}
          </span>
          <div
            style={{
              marginLeft: "auto",
              color: "white",
            }}
          >
            <UploadComponent
              id={"uploadImgDocument" + index}
              name="ImageURI"
              isDraggable={false}
              // isOnlyImg={true}
              maxAcceptedSize={4}
              documentData={empEntry.ImageURI}
              setDocumentData={setImgDocumentData}
              handleOnChange={(target, data) => {
                Modal.destroyAll();
                handleOnDocumentChange(target, data, index);
              }}
            />
          </div>
        </div>,
      ],
      width: 600,
    });
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newdetails = [...details];
    newdetails[index][name] = value;
    setDetails((prevState) => {
      const updatedDetails = [...prevState];
      updatedDetails[index][name] = value;
      return updatedDetails;
    });
  };

  const handleAddEntry = () => {
    setDetails((prevState) => [
      ...prevState,
      {
        college: "",
        degree: "",
        branch: "",

        tenure: "",
        percentage: "",
        ImageURI: [],
      },
    ]);
  };

  const handleDeleteEntry = (index) => {
    if (details.length === 1) {
      return; // Do not allow deleting the last entry
    }
    setDetails((details) => details.filter((_, i) => i !== index));
  };

  const hasValidValues = () => {
    for (const entry of details) {
      if (
        entry.college === "" ||
        entry.degree === "" ||
        entry.branch === "" ||
        entry.tenure === "" ||
        entry.percentage === ""
      ) {
        return false;
      }
      // Check if a document is uploaded for this entry
      if (!entry.ImageURI) {
        return false;
      }
    }
    return true;
  };

  const handlePrevClick = (e) => {
    e.preventDefault();
    props.prevStep();
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    props.nextStep();
  };

  const handleEDSaveClick = () => {
    console.log("details: ", details);
    console.log("Values: ", hasValidValues());
  };

  const handleOnDocumentChange = (target, data, index) => {
    try {
      const { name } = target;
      setDetails((prevState) => {
        const updatedDetails = [...prevState];
        updatedDetails[index]["ImageURI"] = data;
        return updatedDetails;
      });
    } catch {
      setDetails((prevState) => {
        const updatedDetails = [...prevState];
        return updatedDetails;
      });
    }
  };

  const removeImage = (index) => {
    // this is the line that you are looking for
    details.map((data, i) =>
      index === i
        ? setDetails((prevState) => {
            const updatedDetails = [...prevState];
            updatedDetails[index]["ImageURI"] = [];
            return updatedDetails;
          })
        : null
    );
    hiddenFileInput.current.value = null;
  };

  useEffect(() => {
    const page = "EducationDetails";
    const value = [...details];
    console.log("handleSubmit event EducationDetails", page, value);
    props.onChangeformvalue(page, value);
  }, [details]);

  return (
    <Fragment>
      <CardContent>
        {/* Grid 1 */}
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              style={{ fontSize: "x-large", fontWeight: "bold" }}
            >
              Primary Education Details
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddEntry}
              >
                Add Entry
              </Button>
            </Box>
          </Grid>
        </Grid>

        {/* Grid 2 */}
        <Grid
          container
          style={{ minHeight: "55vh", maxHeight: "55vh", overflow: "auto" }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  style={{ fontSize: "small", fontWeight: "bold" }}
                >
                  College / University
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontSize: "small", fontWeight: "bold" }}
                >
                  Degree
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontSize: "small", fontWeight: "bold" }}
                >
                  Branch / Stream
                </TableCell>

                <TableCell
                  align="center"
                  style={{ fontSize: "small", fontWeight: "bold" }}
                >
                  Course Duration (MM/YYYY - MM/YYYY)
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontSize: "small", fontWeight: "bold" }}
                >
                  Percentage
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontSize: "small", fontWeight: "bold" }}
                >
                  Documents
                </TableCell>
                <TableCell
                  align="center"
                  style={{ fontSize: "small", fontWeight: "bold" }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {details.map((entry, index) => (
                <TableRow key={index} style={{ verticalAlign: "top" }}>
                  <TableCell>
                    <Input
                      id={`college-${index}`}
                      name="college"
                      value={props.formdata.EducationDetails?.[index]?.college}
                      onChange={(e) => handleInputChange(index, e)}
                    />
                  </TableCell>

                  <TableCell>
                    <Input
                      id={`degree-${index}`}
                      name="degree"
                      value={props.formdata.EducationDetails?.[index]?.degree}
                      onChange={(e) => handleInputChange(index, e)}
                    />
                  </TableCell>

                  <TableCell>
                    <Input
                      id={`branch-${index}`}
                      name="branch"
                      value={props.formdata.EducationDetails?.[index]?.branch}
                      onChange={(e) => handleInputChange(index, e)}
                    />
                  </TableCell>

                  <TableCell>
                    <Input
                      id={`tenure-${index}`}
                      name="tenure"
                      value={props.formdata.EducationDetails?.[index]?.tenure}
                      onChange={(e) => handleInputChange(index, e)}
                    />
                  </TableCell>

                  <TableCell>
                    <Input
                      id={`percentage-${index}`}
                      name="percentage"
                      value={
                        props.formdata.EducationDetails?.[index]?.percentage
                      }
                      onChange={(e) => handleInputChange(index, e)}
                    />
                  </TableCell>

                  <TableCell>
                    <Button
                      id={`${index}`}
                      variant="contained"
                      color="primary"
                      startIcon={<UploadFileIcon />}
                      onClick={() => {
                        info(entry, index);
                      }}
                    >
                      Add Files
                    </Button>
                  </TableCell>

                  <TableCell>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDeleteEntry(index)}
                    >
                      Delete
                    </Button>
                  </TableCell>

                  <TableCell>
                    {props.formdata.EducationDetails?.[index]?.ImageURI
                      ?.length > 0 && (
                      <div>
                        <Tooltip
                          placement="bottom"
                          title={`Remove all uploaded files for ${props.formdata.EducationDetails?.[index]?.college}`}
                          color={"#ff0000"}
                          key={"#ff0000"}
                        >
                          <button onClick={() => removeImage(index)}>
                            <DeleteIcon sx={{ color: "red" }} />
                          </button>
                        </Tooltip>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>

        {/* Grid 3 */}
        <Grid container spacing={1} justifyContent="flex-end" sx={{ mt: 7 }}>
          <Grid item xs={14} md={9}>
            <Typography style={{ color: "red", fontWeight: "bold" }}>
              *Education details should be in reverse chronological order i.e
              latest first. For Example, PG, UG, HSC and SSC
            </Typography>
          </Grid>
          <Grid item xs={5} md={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handlePrevClick}
            >
              {" "}
              Prev{" "}
            </Button>
          </Grid>
          {/* <Grid item xs={3} md={1}>
            <Button variant="contained" color="secondary" disabled="true">
              {" "}
              Cancel{" "}
            </Button>
          </Grid>
          <Grid item xs={3} md={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEDSaveClick}
            >
              Save
            </Button>
          </Grid> */}
          <Grid item xs={5} md={1}>
            <Button
              variant="contained"
              color="primary"
              disabled={!hasValidValues()}
              onClick={handleNextClick}
            >
              {" "}
              Next{" "}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Fragment>
  );
};

export default EducationForm;
