import {
  DeleteOutlined,
  EyeOutlined,
  InboxOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Form, Modal, Upload, message } from "antd";
import { Fragment } from "react";

const UploadComponent = (props) => {
  let {
    isDraggable,
    isOnlyImg,
    isOnlyPDF,
    maxAcceptedSize,
    documentData,
    setDocumentData,
    id,
    name,
    handleOnChange,
  } = props;
  isDraggable = isDraggable ? isDraggable : false;
  isOnlyImg = isOnlyImg ? isOnlyImg : false;
  isOnlyPDF = isOnlyPDF ? isOnlyPDF : false;
  maxAcceptedSize = maxAcceptedSize ? maxAcceptedSize : 1;
  const [acceptedFileFormat, setAcceptedFileFormat] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState("");
  const [imageUrl, setimageUrl] = useState("");
  const [isPdf, setIsPdf] = useState(false);

  const handleUploadDocument = async (target) => {
    const { files } = target;

    if (target.files && Object.keys(files).length > 0) {
      let AttachmentDT = documentData;
      for (const [key, file] of Object.entries(files)) {
        if (acceptedFileFormat.includes(file.type)) {
          if (file.size / 1000 / 1000 <= maxAcceptedSize) {
            AttachmentDT.push(file);
          } else {
            AttachmentDT = [];
            alert(
              `error, Document size cannot be more than ${maxAcceptedSize} MB`
            );
            break;
          }
        } else {
          AttachmentDT = [];
          alert("error, Document format is invalid");
          break;
        }
      }

      if (
        AttachmentDT &&
        Array.isArray(AttachmentDT) &&
        AttachmentDT.length > 0
      ) {
        setDocumentData([...AttachmentDT]);
        if (handleOnChange) {
          handleOnChange(target, AttachmentDT);
        }
      }
    }
  };

  window.addEventListener(
    "dragover",
    function (e) {
      e.preventDefault();
    },
    false
  );
  window.addEventListener(
    "drop",
    function (e) {
      e.preventDefault();
    },
    false
  );

  useEffect(() => {
    let acceptedFile = [];
    if (isOnlyImg) {
      acceptedFile.push("image/png", "image/jpg", "image/jpeg");
    } else if (isOnlyPDF) {
      acceptedFile.push("application/pdf");
    } else {
      acceptedFile.push(
        "application/pdf",
        "image/png",
        "image/jpg",
        "image/jpeg"
      );
    }
    setAcceptedFileFormat(acceptedFile);
  }, []);

  const handlePreview = async (file) => {
    setPreviewTitle(file.name);
    if (file.type === "application/pdf") {
      setIsPdf(true);
      setimageUrl(URL.createObjectURL(file));
    } else {
      getBase64(file, (imageUrl) => {
        setimageUrl(imageUrl);
      });
    }

    setPreviewVisible(true);
  };
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleCancel = () => {
    setimageUrl("");
    setPreviewVisible(false);
  };

  return (
    <Fragment>
      <Form.Item name={id} className="mb-0">
        <div draggable onDrop={(e) => handleUploadDocument(e.dataTransfer)}>
          <label for={id} style={{ cursor: "pointer" }}>
            {isDraggable ? (
              <div
                draggable
                onDrop={(e) => handleUploadDocument(e.dataTransfer)}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  border: "1px dashed lightgray",
                  borderRadius: "10px",
                  padding: "2%",
                  width: "200px",
                }}
              >
                <span style={{ color: "lightgray", fontSize: "20px" }}>
                  <InboxOutlined />
                </span>
                <span style={{ fontSize: "10px" }}>
                  Click or Drag file to this area to upload
                </span>
                <span style={{ color: "lightgray", fontSize: "10px" }}>
                  Support for a single or bulk upload.
                </span>
              </div>
            ) : (
              <div
                draggable={isDraggable}
                onDrop={(e) => handleUploadDocument(e.dataTransfer)}
                style={{
                  display: "flex",
                  flexDirection: "row ",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid lightgray",
                  borderRadius: "10px",
                  padding: "1%",
                  width: "100px",
                }}
                for="uploadInput"
              >
                <span
                  style={{
                    color: "lightgray",
                    fontSize: "20px",
                    marginRight: "10px",
                  }}
                >
                  <InboxOutlined />
                </span>
                <span style={{ fontSize: "14px" }}>Upload</span>
              </div>
            )}
          </label>
          {documentData &&
          Array.isArray(documentData) &&
          documentData.length > 0
            ? documentData.map((itm, ind) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      width: "50%",
                      // backgroundColor: "red",
                      border: "1px solid lightgray",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "15px",
                      padding: "5px",
                      marginTop: "1%",
                    }}
                  >
                    <EyeOutlined
                      style={{ cursor: "pointer" }}
                      onClick={() => handlePreview(itm)}
                    />
                    <span
                      style={{
                        marginLeft: "10px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {itm.name}
                    </span>
                    <DeleteOutlined
                      style={{ cursor: "pointer", marginLeft: "auto" }}
                      onClick={(e) => {
                        documentData.splice(ind, 1);
                        setDocumentData([...documentData]);
                        if (handleOnChange) {
                          handleOnChange(e.target, documentData);
                        }
                      }}
                    />
                  </div>
                );
              })
            : null}
          <Modal
            visible={previewVisible}
            title={previewTitle ? previewTitle : "Image"}
            footer={null}
            onCancel={handleCancel}
          >
            {isPdf && imageUrl ? (
              <embed
                src={imageUrl ? imageUrl : ""}
                type="application/pdf"
                width="100%"
                style={{ height: "calc(80vh - 50px)" }}
                // aria-label="This object displays an PDF file"
              />
            ) : imageUrl ? (
              <img alt="example" style={{ width: "100%" }} src={imageUrl} />
            ) : null}
          </Modal>
        </div>
        <input
          id={id}
          name={name}
          type="file"
          multiple
          style={{ display: "none" }}
          onChange={(e) => {
            handleUploadDocument(e.target);
          }}
          accept={acceptedFileFormat.join()}
        />
      </Form.Item>
    </Fragment>
  );
};

export default UploadComponent;
