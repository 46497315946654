import React from "react";
import Card from "@mui/material/Card";
import { Paper } from "@mui/material";

const CardStyle = (props) => {
  const { bgColor, blColor, blLeft, width, height, content } = props;

  return (
    <Card
      variant="outlined"
      sx={{
        border: 3,
        borderLeft: blLeft,
        borderRadius: 4,
        boxShadow: 8,
        margin: 4,
        backgroundColor: bgColor,
        borderColor: blColor,
        width: width,
        height: height,
      }}
    >
      <Paper sx={{ width: "100%", maxHeight: "100%", }}>
        {content}
      </Paper>
    </Card>
  );
};

export default CardStyle;
