import React, { Fragment, useEffect, useState } from "react";
import Ribbon from "../../components/Ribbon";
import CardStyle from "../../components/CardStyle";
import { ThemeProvider } from "@mui/material/styles";
import ClientSideBar from "../../components/ClientSideBar";
import { colorTheme, generalTheme } from "../../themes/theme";
import { Box, CardContent, Grid, Button, Input, TextField } from "@mui/material";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";

const GapDetails = (props) => {
    const [details, setDetails] = useState(
        props.formdata?.GapDetail || [
            {
                eduGap: "",
                empGap1: "",
                empGap2: "",
            },
        ]
    );

    const handleInputChange = (index, field, value) => {
        setDetails((prevState) => {
            const newState = [...prevState];
            newState[index][field] = value;
            return newState;
        });
    };

    const handleAddEntry = () => {
        setDetails((prevState) => [
            ...prevState,
            {
                eduGap: "",
                empGap1: "",
                empGap2: "",
            },
        ]);
    };

    const handleDeleteEntry = (index) => {
        if (details.length === 1) {
            return; // Do not allow deleting the last entry
        }
        setDetails((details) => details.filter((_, i) => i !== index));
        // const newGapEntries = [...details];
        // newGapEntries.splice(index, 1);
        // setDetails(newGapEntries);
    };

    const hasValidValues = () => {
        for (const gapEntry of details) {
            if (
                gapEntry.eduGap === '' ||
                gapEntry.empGap1 === '' ||
                gapEntry.empGap2 === ''
            ) {
                return false;
            }
            // Check if a document is uploaded for this entry
            //   if (!gapEntry.document) {
            //     return false;
            //   }
        }
        return true;
    };

    const handlePrevClick = (e) => {
        e.preventDefault();
        props.prevStep();
    };

    const handleGapSaveClick = () => {
        console.log("Entries: ", details)
        console.log("Values: ", hasValidValues())
    };

    const handleNextClick = (e) => {
        e.preventDefault();
        props.nextStep();
    };

    useEffect(() => {
        const page = "GapDetail";
        const value = [...details];
        console.log("Use Effect GapDetail", details);
        props.onChangeformvalue(page, value);
    }, [details]);

    return (
        <Fragment>
            <CardContent>
                {/* Grid 1 */}
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" style={{ fontSize: 'x-large', fontWeight: 'bold' }}>
                            Gap Details
                        </Typography>
                    </Grid>
                </Grid>

                {/* Grid 2 */}
                <div style={{ height: '360px', overflow: 'auto' }}>
                    {details.map((gapEntry, index) => (
                        <>
                            <Grid container spacing={1} key={index}>
                                {index > 0 ?
                                    <Grid item xs={24} md={11} sx={{ mt: 4 }}>
                                        <TextField
                                            label="Gap between Education to Education"
                                            variant="outlined"
                                            fullWidth
                                            value={props.formdata.GapDetail?.[index]?.eduGap}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    index,
                                                    'eduGap',
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                    </Grid>
                                    :
                                    <Grid item xs={24} md={11} sx={{ mt: 2 }}>
                                        <TextField
                                            label="Gap between Education to Education"
                                            variant="outlined"
                                            fullWidth
                                            value={props.formdata.GapDetail?.[index]?.eduGap}
                                            onChange={(e) =>
                                                handleInputChange(
                                                    index,
                                                    'eduGap',
                                                    e.target.value
                                                )
                                            }
                                            required
                                        />
                                    </Grid>
                                }
                                <Grid item xs={24} md={11}>
                                    <TextField
                                        label="Gap between Education to Employment"
                                        variant="outlined"
                                        fullWidth
                                        value={props.formdata.GapDetail?.[index]?.empGap1}
                                        onChange={(e) =>
                                            handleInputChange(
                                                index,
                                                'empGap1',
                                                e.target.value
                                            )
                                        }
                                        required
                                    />
                                </Grid>
                                <Grid item xs={24} md={11}>
                                    <TextField
                                        label="Gap between Employment to Employment"
                                        variant="outlined"
                                        fullWidth
                                        value={props.formdata.GapDetail?.[index]?.empGap2}
                                        onChange={(e) =>
                                            handleInputChange(
                                                index,
                                                'empGap2',
                                                e.target.value
                                            )
                                        }
                                        required
                                    />
                                </Grid>
                                <Grid item xs={24} md={11}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button variant="contained" color="secondary" onClick={() => handleDeleteEntry(index)} style={{ marginLeft: 'auto' }}>
                                            Delete
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </>
                    ))}
                </div>

                {/* Grid 3 */}
                <Grid container spacing={1} justifyContent="flex-end" sx={{ mt: 2 }}>
                    <Grid item xs={4} md={2}>
                        <Button variant="contained" color="secondary" onClick={handlePrevClick}> Prev </Button>
                    </Grid>
                    {/* <Grid item>
                        <Button variant="contained" color="secondary" disabled='true'> Cancel </Button>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={handleGapSaveClick}> Save </Button>
                    </Grid> */}
                    <Grid item xs={4} md={1.8}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!hasValidValues()}
                            onClick={handleNextClick}> Next </Button>
                    </Grid>
                </Grid>
            </CardContent>
        </Fragment>
    );
}
export default GapDetails;