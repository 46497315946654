import React, { useState, useEffect, Fragment } from "react";
import {
  Box,
  CardContent,
  Grid,
  Button,
  Typography,
  TextField,
} from "@mui/material";

const DirectorDetails = (props) => {
  const [details, setDetails] = useState(
    props.formdata?.CompanyDetails || [
      {
        compName: "",
        compLocation: "",
        cinNumber: "",
        directorName: "",
        directorTANPAN: "",
        ImageURI: "",
      },
    ]
  );

  const handleInputChange = (field, value) => {
    setDetails((prevState) => {
      const newState = [...prevState];
      newState[0][field] = value;
      return newState;
    });
  };

  const hasValidValues = () => {
    for (const dirEntry of details) {
      if (
        dirEntry.compName === "" ||
        dirEntry.compLocation === "" ||
        dirEntry.cinNumber === "" ||
        dirEntry.directorName === "" ||
        dirEntry.directorTANPAN === ""
      ) {
        return false;
      }
      // if (!dirEntry.ImageURI) {
      //   return false;
      // }
    }
    return true;
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    props.nextStep();
  };

  const handlePrevClick = (e) => {
    e.preventDefault();
    props.prevStep();
  };

  useEffect(() => {
    const page = "DirectorDetails";
    const value = [...details];
    console.log("handleSubmit event DirectorDetails: ", page, value);
    props.onChangeformvalue(page, value);
  }, [details]);

  return (
    <Fragment>
      <CardContent>
        {/* Grid 1: Header Row */}
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              style={{ fontSize: "x-large", fontWeight: "bold" }}
            >
              Director Details
            </Typography>
          </Grid>
          {/* <Grid item xs={12} md={5}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                // onClick={handleAddEntry}
              >
                Upload Document
              </Button>
            </Box>
          </Grid> */}
        </Grid>

        {/* Grid 2: Form Field */}
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Director Name"
              variant="outlined"
              fullWidth
              value={props.formdata.DirectorDetails?.[0]?.directorName}
              onChange={(e) =>
                handleInputChange("directorName", e.target.value)
              }
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Company Name"
              variant="outlined"
              fullWidth
              value={props.formdata.DirectorDetails?.[0]?.compName}
              onChange={(e) => handleInputChange("compName", e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={24} md={12}>
            <TextField
              label="Company Location"
              variant="outlined"
              fullWidth
              value={props.formdata.DirectorDetails?.[0]?.compLocation}
              onChange={(e) =>
                handleInputChange("compLocation", e.target.value)
              }
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="TAN or PAN"
              variant="outlined"
              fullWidth
              value={props.formdata.DirectorDetails?.[0]?.directorTANPAN}
              onChange={(e) =>
                handleInputChange("directorTANPAN", e.target.value)
              }
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="CIN Number"
              variant="outlined"
              fullWidth
              value={props.formdata.DirectorDetails?.[0]?.cinNumber}
              onChange={(e) => handleInputChange("cinNumber", e.target.value)}
              required
            />
          </Grid>
        </Grid>

        {/* Grid 3: Navigation Button Row*/}
        <Grid container spacing={1} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Grid item xs={6} md={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handlePrevClick}
            >
              {" "}
              Prev{" "}
            </Button>
          </Grid>

          <Grid item xs={6} md={2}>
            <Button
              variant="contained"
              color="primary"
              disabled={!hasValidValues()}
              onClick={handleNextClick}
            >
              {" "}
              Next{" "}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Fragment>
  );
};

export default DirectorDetails;
