import { useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

import Wrapper from "../../components/Wrapper";
import ClientController from "../client/ClientController";
import CustomInputTable from "../infomate/CustomInputTable";
import CustomDataTable from "../../components/CustomDataTable";
import { getLocalStorageItem } from "../../helpers/LocalStorage";
import NotificationAlert from "../../components/NotificationAlert";
import {
  addApplicantData,
  getAllApplicantList,
  getApplicantList,
} from "../../services/applicantServices";

const CaseStatusPage = () => {
  const dispatch = useDispatch();

  const [res, setRes] = useState([]);
  const [rows, setRows] = useState([]);
  const [userRole, setUserRole] = useState();
  const [clientCode, setClientCode] = useState();
  const [headings, setHeadings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [headingsInput, setHeadingsInput] = useState([]);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  useEffect(() => {
    setUserRole(getLocalStorageItem("loginRole"));
    setClientCode(getLocalStorageItem("loginClientCode"));
  }, []);

  useEffect(() => {
    if (userRole) {
      const onSuccess = (result) => {
        console.log("Second ye: set", result.data);
        if (result.data.length > 0) {
          setRes(result.data);
        } else {
          const headers = [
            "Infomate ID",
            "Candidate Name",
            "Date of Initiation",
            "Date of Last Close",
            "Report Type",
            "Current Employment Date (Doc Receive)",
            "Color Code",
          ];
          if (userRole === "1" || userRole === "2") {
            // setIsLoading(false);
            headers.splice(1, 0, "Client Code"); // client code added in the column instead of applicant id.
          } else if (userRole === "4") {
            headers.splice(1, 0, "Applicant Id(Client)");
          }
          const headersInput = [...headers, "Actions"];
          setHeadings(headers);
          setHeadingsInput(headersInput);
          let fillRowsData = fillRows();
          setRows([...fillRowsData]);
          setIsLoading(false);
        }
      };
      console.log("Second ye");
      if (userRole === "1" || userRole === "2") {
        getAllApplicantList(onSuccess, dispatch);
      } else if (userRole === "4" && clientCode) {
        getApplicantList(onSuccess, dispatch, clientCode);
      }
    }
  }, [userRole, clientCode]);

  useEffect(() => {
    if (res && res.length > 0) {
      console.log("Third ye", res, userRole);
      const headers = [
        "Infomate ID",
        "Candidate Name",
        "Date of Initiation",
        "Date of Last Close",
        "Report Type",
        "Current Employment Date (Doc Receive)",
        "Color Code",
      ];
      if (userRole === "1" || userRole === "2") {
        // setIsLoading(false);
        headers.splice(1, 0, "Client Code"); // client code added in the column instead of applicant id.
      } else if (userRole === "4") {
        headers.splice(1, 0, "Applicant Id(Client)");
      }
      const headersInput = [...headers, "Actions"];
      console.log("ider se aya");
      setHeadings(headers);
      setHeadingsInput(headersInput);
      let fillRowsData = fillRows();
      console.log("fillRowsData", fillRowsData);
      setRows([...fillRowsData]);
      console.log("nikal gaya");
      setIsLoading(false);
    }
  }, [res]);

  const fillRows = () => {
    let rowArr = [];
    res.forEach((row) => {
      console.log("userRole", userRole);
      let rowObj = {};
      rowObj["Infomate ID"] = row.infomate360ReferenceNumber;
      if (userRole === "1" || userRole === "2") {
        rowObj["Client Code"] = row.clientCode; // client code added in the column instead of applicant id.
      } else if (userRole === "4") {
        rowObj["Applicant Id(Client)"] = row.empId;
      }
      rowObj["Candidate Name"] = row.applicantName;
      rowObj["Date of Initiation"] = row.dateOfInitiation;
      rowObj["Date of Last Close"] = row.dateOfLastClose;
      rowObj["Report Type"] = row.reportType;
      rowObj["Current Employment Date(Doc Receive)"] =
        row.currentEmploymentDate;
      rowObj["Color Code"] = row.colorCode;
      rowArr.push(rowObj);
    });
    // console.log("rowArr", rowArr);
    return rowArr;
  };

  const onDownload = (e, row) => {
    e.preventDefault();
    //TODO Delete functionality Code
    console.log("Download Clicked!", row);
  };
  const onSave = async (e, row, rowIndex) => {
    e.preventDefault();
    let resultRow = res[rowIndex];
    resultRow.infomate360ReferenceNumber = row["Infomate ID"];
    if (userRole === "1" || userRole === "2") {
      resultRow.clientCode = row["Client Code"]; // client code added in the column instead of applicant id.
    } else if (userRole === "4") {
      resultRow.empId = row[["Applicant Id(Client)"]];
    }
    resultRow.applicantName = row["Candidate Name"];
    resultRow.dateOfInitiation = row["Date of Initiation"];
    resultRow.dateOfLastClose = row["Date of Last Close"];
    resultRow.reportType = row["Report Type"];
    resultRow.currentEmploymentDate =
      row["Current Employment Date (Doc Receive)"];
    resultRow.colorCode = row["Color Code"];
    const onSuccess = (res) => {
      if (res.status === 200) {
        console.log("status 200");
        setNotify({
          isOpen: true,
          message: "Record Saved Successfully",
          type: "success",
        });
      }
    };
    await addApplicantData(onSuccess, dispatch, resultRow);
    console.log("Save", row);
  };
  const actionsClient = [{ name: "download", onClick: onDownload }]; //TODO
  const actionsEdit = [{ name: "save", onClick: onSave }];

  const [selectedMenuItem, setSelectedMenuItem] = useState({
    id: 1,
    menuName: "Case Status",
  });

  return (
    <>
      {notify.isOpen && (
        <NotificationAlert notify={notify} setNotify={setNotify} />
      )}
      <Wrapper
        title={selectedMenuItem.menuName}
        selectedMenuItem={setSelectedMenuItem}
        setSelectedMenuItem={setSelectedMenuItem}
        content={
          isLoading ? (
            <CircularProgress />
          ) : (userRole === "1" || userRole === "2") &&
            selectedMenuItem.id === 1 ? (
            <CustomInputTable
              headings={headingsInput}
              tableBody={rows}
              actions={actionsEdit}
              status={userRole}
            />
          ) : userRole === "4" && selectedMenuItem.id === 1 ? (
            <CustomDataTable headings={headings} tableBody={rows} />
          ) : userRole === "4" || selectedMenuItem.id === 2 ? (
            <ClientController />
          ) : null
        }
      ></Wrapper>
    </>
  );
};

export default CaseStatusPage;
