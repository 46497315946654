import { getRequests } from "./commonServices";
import { BASE_URL } from "./constants";

export const getClientSpecific = async (onSuccess, dispatch, companyName) => {
  return await getRequests(
    onSuccess,
    dispatch,
    `${BASE_URL}client/specific/${companyName}`
  );
};

export const getClientSpecificByCode = async (
  onSuccess,
  dispatch,
  companyCode
) => {
  return await getRequests(
    onSuccess,
    dispatch,
    `${BASE_URL}client/codeSpecific/${companyCode}`
  );
};

export const getClientSpecificList = async (onSuccess, dispatch) => {
  return await getRequests(onSuccess, dispatch, `${BASE_URL}client/specifics`);
};
