import React, { Fragment, useState, useEffect } from "react";

import { DatePicker } from "antd";
import { Grid, CardContent } from "@mui/material";

import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import dayjs from "dayjs";
import validator from "validator";
import { countries } from "countries-list";

import { getLocalStorageItem } from "../../helpers/LocalStorage";

const PersonalDetails = (props) => {
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [details, setDetails] = useState(
    props.formdata?.PersonalDetails || [
      {
        firstName: "",
        lastName: "",
        fatherName: "",
        motherName: "",
        dob: "",
        birthPlace: "",
        maritalStatus: "",
        nationality: "",
        aadhaarNumber: "",
        uan: "",
        email: "",
        passportNumber: "",
        passportIssuedAt: "",
        passportIssuedOn: "",
      },
    ]
  );

  if (
    props &&
    props?.formdata &&
    props?.formdata?.PersonalDetails &&
    props?.formdata?.PersonalDetails?.[0]
  ) {
    if (getLocalStorageItem("loginRole") === "3") {
      props.formdata.PersonalDetails[0].email =
        getLocalStorageItem("loginEmail");
    } else {
      props.formdata.PersonalDetails[0].email =
        props.applicantData.BasicDetail[0].applicantEmail;
    }
  }
  // Variable with Array of Nationality List
  const nationalityList = Object.values(countries);

  // Variable with Sort Array of Nationality List
  const sortedNationalityList = nationalityList.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  // Variable with index value of India
  const indiaIndex = sortedNationalityList.findIndex(
    (country) => country.name === "India"
  );

  // Variable with Array of Nationality List with India on top
  sortedNationalityList.splice(
    0,
    0,
    sortedNationalityList.splice(indiaIndex, 1)[0]
  );

  const handleDropDownStatusChange = (event, id) => {
    console.log("Change Event(DropDown): ", id, " : ", event.target.value);
    const { value } = event.target;
    setDetails((prevState) => {
      console.log("Previous State: ", prevState);
      const updatedDetails = [...prevState];
      updatedDetails[0][id] = value;
      return updatedDetails;
    });
    console.log("Form Values: ", details);
  };

  const handleFormChange = (event) => {
    if (event.target.id === "email") {
      setIsValidEmail(validator.isEmail(event.target.value));
    }
    const { id, value } = event.target;
    setDetails((prevState) => {
      const updatedDetails = [...prevState];
      updatedDetails[0][id] = value;
      return updatedDetails;
    });
  };

  const handleDateChange = (key, date) => {
    setDetails((prevState) => {
      const updatedDetails = [...prevState];
      updatedDetails[0][key] = date;
      return updatedDetails;
    });
  };

  const hasValidValues = () => {
    for (const personalEntry of details) {
      if (
        personalEntry.firstName !== "" &&
        personalEntry.lastName !== "" &&
        personalEntry.fatherName !== "" &&
        personalEntry.motherName !== "" &&
        personalEntry.dob !== "" &&
        personalEntry.birthPlace !== "" &&
        personalEntry.maritalStatus !== "" &&
        personalEntry.nationality !== "" &&
        personalEntry.aadhaarNumber !== "" &&
        personalEntry.uan !== ""
      ) {
        return true;
      }
    }
    return false;
  };

  const handlePDSaveClick = () => {
    console.log("Entries: ", details);
    console.log("Values: ", hasValidValues());
  };

  const handleNextClick = (e) => {
    e.preventDefault();
    props.nextStep();
  };

  useEffect(() => {
    const page = "PersonalDetails";
    const value = [...details];
    console.log("handleSubmit eveent PersonalDetails", page, value);
    props.onChangeformvalue(page, value);
  }, [details]);

  return (
    <Fragment>
      <CardContent>
        {/* Grid 1 */}
        <Grid container style={{ marginBottom: "10px" }}>
          <Grid item xs={24} md={12}>
            <Typography
              variant="h6"
              style={{ fontSize: "x-large", fontWeight: "bold" }}
            >
              Personal Details
            </Typography>
          </Grid>
        </Grid>

        {/* Grid 2 */}
        <Grid
          container
          spacing={1}
          style={{ maxHeight: "360px", overflow: "auto" }}
        >
          <Grid item xs={12} md={5.5}>
            <TextField
              id="firstName"
              label="First name"
              variant="outlined"
              fullWidth
              value={props.formdata.PersonalDetails?.[0]?.firstName}
              onChange={handleFormChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="lastName"
              label="Last name"
              variant="outlined"
              fullWidth
              value={props.formdata.PersonalDetails?.[0]?.lastName}
              onChange={handleFormChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={5.5}>
            <TextField
              id="fatherName"
              label="Father Name"
              variant="outlined"
              fullWidth
              value={props.formdata.PersonalDetails?.[0]?.fatherName}
              onChange={handleFormChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="motherName"
              label="Mother Name"
              variant="outlined"
              fullWidth
              value={props.formdata.PersonalDetails?.[0]?.motherName}
              onChange={handleFormChange}
              required
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              style={{
                color: "black",
                fontWeight: "bold",
                marginTop: "10px",
              }}
            >
              Date of Birth
            </Typography>
          </Grid>
          <Grid item xs={6} md={3.5}>
            <DatePicker
              value={
                props.formdata.PersonalDetails?.[0]?.dob
                  ? dayjs(
                      props.formdata.PersonalDetails?.[0]?.dob,
                      "DD/MM/YYYY"
                    )
                  : null
              }
              popupStyle={{ width: "300px", height: "150px" }}
              onChange={(date, dateString) =>
                handleDateChange("dob", dateString)
              }
              format="DD/MM/YYYY"
            />
          </Grid>
          {/* Birth Field*/}
          <Grid item xs={12} md={6}>
            <TextField
              id="birthPlace"
              label="Birth Place"
              variant="outlined"
              fullWidth
              value={props.formdata.PersonalDetails?.[0]?.birthPlace}
              onChange={handleFormChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={5.5}>
            <FormControl fullWidth>
              <InputLabel id="marital-status-select-label">
                Marital Status
              </InputLabel>
              <Select
                labelId="marital-status-select-label"
                label="Marital Status"
                onChange={
                  (event) => handleDropDownStatusChange(event, "maritalStatus") // props.onChangeformvalue(event);
                }
                fullWidth
                value={props.formdata.PersonalDetails?.[0]?.maritalStatus}
              >
                <MenuItem value="single">Single</MenuItem>
                <MenuItem value="married">Married</MenuItem>
                <MenuItem value="divorced">Divorced</MenuItem>
                <MenuItem value="widowed">Widowed</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id="nationality-select-label">Nationality</InputLabel>
              <Select
                labelId="nationality-select-label"
                label="Nationality"
                value={props.formdata.PersonalDetails?.[0]?.nationality}
                onChange={(event) =>
                  handleDropDownStatusChange(event, "nationality")
                } // props.onChangeformvalue(event);
              >
                {sortedNationalityList.map((country) => (
                  <MenuItem key={country.name} value={country.name}>
                    {" "}
                    {country.name}{" "}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={5.5}>
            <TextField
              id="passportNumber"
              label="Passport Number"
              variant="outlined"
              fullWidth
              value={props.formdata.PersonalDetails?.[0]?.passportNumber}
              onChange={handleFormChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="passportIssuedAt"
              label="Passport Issued at"
              variant="outlined"
              fullWidth
              value={props.formdata.PersonalDetails?.[0]?.passportIssuedAt} // Need Update
              onChange={handleFormChange}
            />
          </Grid>{" "}
          {/* Need Update */}
          <Grid item xs={6} md={2}>
            <Typography
              style={{
                color: "black",
                fontWeight: "bold",
                marginTop: "10px",
              }}
            >
              Passport Issued on
            </Typography>
          </Grid>
          <Grid item xs={6} md={3.5}>
            <DatePicker
              popupStyle={{ width: "300px", height: "150px" }}
              value={
                props.formdata.PersonalDetails?.[0]?.passportIssuedOn
                  ? dayjs(
                      props.formdata.PersonalDetails?.[0]?.passportIssuedOn,
                      "DD/MM/YYYY"
                    )
                  : null
              }
              onChange={(date, dateString) =>
                handleDateChange("passportIssuedOn", dateString)
              }
              format="DD/MM/YYYY"
            />
          </Grid>
          {/* Need Props Creation */} {/* Passport Issued Date Field*/}
          <Grid item xs={12} md={6}>
            <TextField
              id="aadhaarNumber"
              label="Aadhaar Number"
              variant="outlined"
              fullWidth
              value={props.formdata.PersonalDetails?.[0]?.aadhaarNumber}
              onChange={handleFormChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={5.5}>
            <TextField
              id="uan"
              label="UAN"
              variant="outlined"
              fullWidth
              value={props.formdata.PersonalDetails?.[0]?.uan}
              onChange={handleFormChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="email"
              error={!isValidEmail}
              variant="outlined"
              fullWidth
              value={props.formdata.PersonalDetails?.[0]?.email}
              disabled={true}
            />
          </Grid>
        </Grid>

        {/* Grid 3 */}
        <Grid container spacing={1} justifyContent="flex-end" sx={{ mt: 2 }}>
          <Grid item xs={4} md={2}>
            <Button variant="contained" color="secondary" disabled="True">
              {" "}
              Prev{" "}
            </Button>
          </Grid>
          {/* <Grid item>
            <Button variant="contained" color="secondary"> Cancel </Button>
          </Grid> */}
          {/* <Grid item>
            <Button variant="contained" color="primary" onClick={handlePDSaveClick}> Save </Button>
          </Grid> */}
          <Grid item xs={4} md={1.5}>
            <Button
              variant="contained"
              color="primary"
              disabled={!hasValidValues()}
              onClick={handleNextClick}
            >
              {" "}
              Next{" "}
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Fragment>
  );
};

export default PersonalDetails;
