import "./App.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";

import Wrapper from "./components/Wrapper";
import LoginScreen from "./views/login/LoginScreen";
import { setError } from "./store/error/errorSlice";
import CaseStatusPage from "./views/caseInitiation/CaseStatusPage";
import CandidateController from "./views/candidate/candidateController";

function App() {
  const errorObj = useSelector((state) => state.error);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      errorObj.message === "TokenExpiredError" ||
      errorObj.message === "InvalidToken" ||
      errorObj.message === "AuthenticationFailed"
    ) {
      window.alert(
        "Your session expired! Please Login Again!! :" + errorObj.name
      );
      navigate("/");
      window.location.reload(false);
    } else if (errorObj.message !== "" && errorObj.name !== "") {
      window.alert(errorObj.message + " : " + errorObj.name);
    }
    return () => {
      dispatch(setError(["", ""]));
    };
  }, [errorObj, navigate]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<LoginScreen />} />
        <Route path="/clientPage" element={<CaseStatusPage />} />
        <Route
          path="/applicantPage"
          element={
            <Wrapper
              title={"Initiate Case"}
              selectedMenuItem={"Initiate Case"}
              setSelectedMenuItem={"Initiate Case"}
              content={<CandidateController />}
            ></Wrapper>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
