import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";

import Success from "../messages/Success";
import GapDetails from "../testmulitpage/GapDetails";
import BankDetails from "../testmulitpage/BankDetails";
import Declaration from "../testmulitpage/Declaration";
import PresentAddress from "../testmulitpage/PresentAddress";
import CompanyDetails from "../testmulitpage/CompanyDetails";
import PersonalDetails from "../testmulitpage/PersonalDetails";
import EducationDetails from "../testmulitpage/EducationDetails";
import ReferenceDetails from "../testmulitpage/ReferenceDetails";
import DirectorsDetails from "../testmulitpage/DirectorsDetails";
import AdditionalDetails from "../testmulitpage/AdditionalDetails";
import EmploymentDetails from "../testmulitpage/EmploymentDetails";
import NameChangeDetails from "../testmulitpage/NameChangeDetails";

import { getApplicantData } from "../../services/applicantServices";
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../helpers/LocalStorage";
import EmailSent from "../messages/EmailSent";

// ['PersonalDetails', 'EducationVerification', 'EmploymentVerification',
//'UANVerification', 'GapVerification', 'ReferenceCheck', 'IdentityVerification',
//'DatabaseVerification', 'CriminalVerification', 'AdditionalDetails', 'Declaration', 'Success']

const CandidateController = (props) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [formdata, setFormdata] = useState({});
  const [currentPage, setCurrentPage] = useState("");
  const [checkListState, setCheckListState] = useState(["PersonalDetails"]);

  const applicantData = props.applicantData;
  const loggedInUserEmail = getLocalStorageItem("loginEmail");

  // Proceed to next step
  const nextStep = () => {
    setStep(step + 1);
  };

  // Go back to prev step
  const prevStep = () => {
    setStep(step - 1);
  };

  const specPrevStep = () => {
    setStep(step - 2);
  };

  // Handle fields change
  const onChangeformvalue = (page, value) => {
    setFormdata({
      ...formdata,
      [page]: value,
    });
  };

  useEffect(() => {
    setCurrentPage(checkListState[step]);
  }, [checkListState]);

  useEffect(() => {
    setCurrentPage(checkListState[step]);
  }, [step]);

  useEffect(() => {
    console.log("FormData Candidate Page: ", props);
    if (getLocalStorageItem("loginRole") === "3" && loggedInUserEmail) {
      fetchData(loggedInUserEmail);
    } else if (getLocalStorageItem("loginRole") === "4") {
      fetchData(props?.applicantData?.BasicDetail?.[0]?.applicantEmail);
    } else if (
      getLocalStorageItem("loginRole") === "2" ||
      getLocalStorageItem("loginRole") === "1"
    ) {
      fetchData(props?.applicantData?.BasicDetail?.[0]?.applicantEmail);
    }
  }, [loggedInUserEmail]);

  const fetchData = async (email) => {
    await getApplicantData(handleOnSuccess, dispatch, email);
  };

  const handleOnSuccess = (res) => {
    if (res && res.data && res.status === 200 && res.data.packages) {
      let includedPages = [
        "PersonalDetails",
        "AddressVerification",
        "EducationVerification",
        "EmploymentVerification",
        "GapVerification",
        "ReferenceCheck",
        "CompanyCheckVerification",
        "BankStatementVerification",
        "OfficialNameChangeVerification",
      ];
      // console.log("res.data.packages before", res.data.packages);
      let final_list = res.data.packages.filter((i) =>
        includedPages.includes(i)
      );
      // console.log("final_list", final_list);
      // console.log("res.data.packages", res.data.packages);
      setCheckListState([
        ...checkListState,
        ...final_list,
        "AdditionalDetails",
        "Declaration",
        "Success",
      ]);

      let checkList = [];
      checkList.push(res.data.packages);
      setFormdata({
        ...formdata,
        checkList: checkList,
      });
      setLocalStorageItem("createdBy", res.data.createdBy);
    }
  };

  switch (currentPage) {
    case "PersonalDetails":
      return (
        <PersonalDetails
          nextStep={nextStep}
          onChangeformvalue={onChangeformvalue}
          formdata={formdata}
          applicantData={applicantData}
        />
      );
    case "AddressVerification":
      return (
        <PresentAddress
          nextStep={nextStep}
          prevStep={prevStep}
          onChangeformvalue={onChangeformvalue}
          formdata={formdata}
        />
      );
    case "EducationVerification":
      return (
        <EducationDetails
          nextStep={nextStep}
          prevStep={prevStep}
          onChangeformvalue={onChangeformvalue}
          formdata={formdata}
        />
      );
    case "EmploymentVerification":
      return (
        <EmploymentDetails
          nextStep={nextStep}
          prevStep={prevStep}
          onChangeformvalue={onChangeformvalue}
          formdata={formdata}
        />
      );
    case "GapVerification":
      return (
        <GapDetails
          nextStep={nextStep}
          prevStep={prevStep}
          onChangeformvalue={onChangeformvalue}
          formdata={formdata}
        />
      );
    case "CompanyCheckVerification":
      return (
        <CompanyDetails
          nextStep={nextStep}
          prevStep={prevStep}
          onChangeformvalue={onChangeformvalue}
          formdata={formdata}
        />
      );
    case "DirectorshipVerification":
      return (
        <DirectorsDetails
          nextStep={nextStep}
          prevStep={prevStep}
          onChangeformvalue={onChangeformvalue}
          formdata={formdata}
        />
      );
    case "BankStatementVerification":
      return (
        <BankDetails
          nextStep={nextStep}
          prevStep={prevStep}
          onChangeformvalue={onChangeformvalue}
          formdata={formdata}
        />
      );
    case "OfficialNameChangeVerification":
      return (
        <NameChangeDetails
          nextStep={nextStep}
          prevStep={prevStep}
          onChangeformvalue={onChangeformvalue}
          formdata={formdata}
        />
      );
    case "ReferenceCheck":
      return (
        <ReferenceDetails
          nextStep={nextStep}
          prevStep={prevStep}
          onChangeformvalue={onChangeformvalue}
          formdata={formdata}
        />
      );
    case "AdditionalDetails":
      return (
        <AdditionalDetails
          nextStep={nextStep}
          prevStep={prevStep}
          onChangeformvalue={onChangeformvalue}
          formdata={formdata}
        />
      );
    case "Declaration":
      return (
        <Declaration
          nextStep={nextStep}
          prevStep={prevStep}
          specPrevStep={specPrevStep}
          onChangeformvalue={onChangeformvalue}
          formdata={formdata}
          applicantData={applicantData}
        />
      );
    case "Success":
      return getLocalStorageItem("loginRole") === "3" ? (
        <Success />
      ) : (
        <EmailSent />
      );
    default:
      console.log("This is a multi-step form built with React.");
  }
};

export default CandidateController;
